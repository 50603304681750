import { State } from './auth.state';
import { LoginResponse } from '@shared/models';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { SessionState } from '@shared/services/session/session-state';

export const getAuthLoading = (state: State): boolean => state.loading;
export const getAuth = (state: State): LoginResponse => state.oauth;
export const getSessionState = (state: State): SessionState => state.sessionState;
export const getAuthInitializing = (state: State): boolean => state.initializing;
const getRefreshLoading = (state: State): boolean => state.refreshTokenLoading;
const getRefreshLoginInProcess = (state: State): boolean => state.refreshLoginInProcess;
const getError = (state: State): string[] => state.error;

export const selectAuthState: MemoizedSelector<object, State>
  = createFeatureSelector<State>('auth');

export const selectAuthLoading: MemoizedSelector<Object, boolean> = createSelector(selectAuthState, getAuthLoading);

export const selectAuth: MemoizedSelector<Object, LoginResponse> = createSelector(selectAuthState, getAuth);

export const selectSessionState: MemoizedSelector<Object, SessionState> = createSelector(selectAuthState, getSessionState);

export const selectAuthInitializing: MemoizedSelector<Object, boolean> = createSelector(selectAuthState, getAuthInitializing);

export const selectRefreshTokenLoading: MemoizedSelector<Object, boolean> = createSelector(selectAuthState, getRefreshLoading);

export const selectRefreshLoginInProcess: MemoizedSelector<Object, boolean> = createSelector(selectAuthState, getRefreshLoginInProcess);

export const selectAuthError: MemoizedSelector<Object, any> = createSelector(selectAuthState, getError);
