import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { filter, map } from 'rxjs/operators';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import LocaleUs from '@angular/common/locales/en-US-POSIX';
import localeUsExtra from '@angular/common/locales/extra/en-US-POSIX';
import { select, Store } from '@ngrx/store';
import { RouterStoreSelectors, UserStoreSelectors } from '@app/root-store';
import { I18nDataService } from '@shared/services/i18n-data/i18n-data.service';
import { forkJoin, Observable, Subject } from 'rxjs';
import { OverlayService } from '@shared/services/overlay/overlay.service';

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(LocaleUs, 'en', localeUsExtra);

export enum LanguageId {
  German = 3,
  English = 4,
}

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  private translationGroups: string[] = [];

  private translationUpdate$ = new Subject<void>();
  private translationsLoaded$ = new Subject<void>();

  constructor(
    private translateService: TranslateService,
    private localStorageService: LocalStorageService,
    private store$: Store<{}>,
    private i18nDataService: I18nDataService,
    private overlayService: OverlayService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.setDefaultLanguage();
    this.store$.pipe(select(UserStoreSelectors.selectUser))
      .pipe(filter(user => user && !!user.languageCode))
      .subscribe(user => this.currentLanguage = user.languageCode);

    this.store$.pipe(select(RouterStoreSelectors.selectTranslationGroups))
      .pipe(filter(groups => !!groups))
      .subscribe(groups => this.translationGroups = groups);
  }

  private loadTranslations(lang: string) {
    this.overlayService.setShowOverlay(true);

    const translations = this.translationGroups.map(group => this.fetchTranslations(lang, group));

    forkJoin(translations)
      .subscribe(() => {
        this.translationsLoaded$.next();
        this.overlayService.setShowOverlay(false);
      });
  }

  private fetchTranslations(lang: string, group: string): Observable<void> {
    return this.i18nDataService.getTranslations$(lang, group)
      .pipe(map(next => {
        this.translateService.setTranslation(lang, next, true);
      }));
  }

  private setDefaultLanguage() {
    const lang = this.localStorageService.getItem('lastUsedLanguage') || this.translateService.getBrowserLang();
    this.localStorageService.setItem('lastUsedLanguage', lang);
    this.translateService.use(lang || 'en');
    this.document.documentElement.lang = lang || 'en';
  }

  set currentLanguage(lang: string) {
    this.localStorageService.setItem('lastUsedLanguage', lang);
    if (lang !== this.currentLanguage) {
      this.translateService.use(lang);
      this.document.documentElement.lang = lang;
      this.loadTranslations(lang);
      this.translationUpdate$.next();
    }
  }

  reloadTranslations(group: string) {
    // only allow reloading of groups that are translated in the frontend
    if (['OPERATE', 'COMMON', 'AUTH'].includes(group)) {
      this.i18nDataService.getTranslations$(this.currentLanguage, group)
        .subscribe(res => this.translateService.setTranslation(this.currentLanguage, res, true));
    }
  }

  /**
   * Will trigger when a language is changed. New translations are not loaded yet.
   */
  get languageChange$(): Observable<void> {
    return this.translationUpdate$;
  }

  /**
   * Will trigger when a new language with translations is loaded completely.
   */
  get languageLoaded$(): Observable<void> {
    return this.translationsLoaded$;
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  get currentLanguageCode(): LanguageId {
    if (this.translateService.currentLang === 'de') {
      return LanguageId.German;
    } else if (this.translateService.currentLang === 'en') {
      return LanguageId.English;
    } else {
      return null;
    }
  }

}
