import { Actions, ActionTypes } from './impersonation.actions';
import { featureAdapter, initialState } from './impersonation.state';

export function featureReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.LOAD_IMPERSONATION_TARGETS:
    case ActionTypes.LOAD_IMPERSONATION_TARGETS_LOCAL: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    }

    case ActionTypes.LOAD_IMPERSONATION_TARGETS_SUCCESS: {
      return featureAdapter.setAll(action.payload, {
        ...state,
        loading: false,
        loaded: true,
        error: null,
      });
    }

    case ActionTypes.LOAD_IMPERSONATION_TARGETS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.error
      };
    }

    case ActionTypes.SET_IMPERSONATION:
    case ActionTypes.LOAD_IMPERSONATION_TARGET_SUCCESS: {
      return {
        ...state,
        impersonated: action.payload,
        error: null,
      };
    }

    case ActionTypes.IMPERSONATION_LOGOUT:
      return {
        ...initialState,
      };

    default: {
      return state;
    }
  }
}
