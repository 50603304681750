<div class="alert-container col-sm-8 col-md-6 col-lg-4 fixed-top">
  <div *ngFor="let alert of alerts"
       class="alert {{ cssClass(alert) }} alert-dismissible animated fadeIn"
       (mouseenter)="alert.stopTimeout()" (mouseleave)="alert.resetTimeout()">
    <button (click)="alert.dismiss()" type="button" class="close" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 *ngIf="alert.heading" class="alert-heading">{{alert.heading}}</h5>
    <div *ngIf="alert.message">{{alert.message}}</div>
    <div *ngIf="alert.html" [innerHTML]="alert.html"></div>
  </div>
</div>
