import { BehaviorSubject, Observable } from 'rxjs';

export class Alert {
  private dismissedSubject = new BehaviorSubject<boolean>(false);
  private timeoutRef: number;

  constructor(public type: AlertType,
              public message: string,
              public heading: string,
              public html: string,
              private timeoutMs: number = 15000) {
    this.setupTimeout();
  }

  set timeout(milliseconds: number) {
    this.timeoutMs = milliseconds;
    if (this.dismissedSubject.getValue()) {
      throw new Error('Alert already dismissed');
    }
    this.resetTimeout();
  }

  get dismissed$(): Observable<boolean> {
    return this.dismissedSubject.asObservable();
  }

  dismiss() {
    this.dismissedSubject.next(true);
    this.dismissedSubject.complete();
  }

  stopTimeout() {
    if (this.timeoutRef) {
      window.clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
  }

  startTimeout() {
    if (this.timeoutRef) {
      window.clearTimeout(this.timeoutRef);
      this.timeoutRef = null;
    }
    this.setupTimeout();
  }

  resetTimeout() {
    if (this.timeoutRef) {
      window.clearTimeout(this.timeoutRef);
      this.setupTimeout();
    }
  }

  private setupTimeout() {
    if (this.timeoutMs === 0) {
      return;
    }
    this.timeoutRef = window.setTimeout(() => {
      this.dismiss();
    }, this.timeoutMs);
  }
}

export enum AlertType {
  Success,
  Error,
  Info,
  Warning
}
