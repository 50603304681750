import { Inject, Injectable } from '@angular/core';
import { cloneDeep, get, set } from 'lodash-es';
import { LoggingService } from '../logging/logging.service';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable()
export class LocalStorageService {

  private localStorageKey = this.appConfig.name + 'OperateNgx';
  private storageData: {};

  constructor(
    private logger: LoggingService,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    this.refresh();
  }

  getItem(path: string): any {
    this.refresh();
    return cloneDeep(get(this.storageData, path));
  }

  getUserItem(userId: number, path: string): any {
    return this.getItem(this.generateUserPath(userId, path));
  }

  setItem(path: string, item: any) {
    set(this.storageData, path, cloneDeep(item));
    this.persist();
  }

  setUserItem(userId: number, path: string, item: any) {
    this.setItem(this.generateUserPath(userId, path), item);
  }

  private refresh() {
    try {
      this.storageData = JSON.parse(localStorage.getItem(this.localStorageKey)) || {};
    } catch (e) {
      this.logger.warn('LocalStorageService: Cannot parse json from local storage. Data is being reseted.');
      this.storageData = {};
      this.persist();
    }
  }

  private persist() {
    localStorage.setItem(this.localStorageKey, JSON.stringify(this.storageData));
  }

  private generateUserPath(userId: number, path: string): string {
    if (userId == null) {
      throw new Error('no userId Supplied');
    }
    return `user-${userId}.${path}`;
  }

}
