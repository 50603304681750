import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { AlertService } from '../alert/alert.service';
import { catchError, throttleTime } from 'rxjs/operators';
import { LoggingService } from '../logging/logging.service';
import { Alert } from '../alert/alert.model';
import { ResponseErrorPipe } from '../../pipes/response-error/response-error.pipe';
import { TranslateService } from '@ngx-translate/core';
import { TranslationKeyService } from '@shared/services/translation-key/translation-key.service';
import { HttpErrorAlert } from '@shared/services/http-error/http-error-alert';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors, RootStoreState } from '@app/root-store';

@Injectable()
export class HttpErrorInterceptorService implements HttpInterceptor {

  private offlineSubject = new Subject();
  private lastOfflineAlert: Alert;
  private refreshTokenLoading: boolean;
  private refreshLoginInProcess: boolean;

  constructor(
    private alertService: AlertService,
    private logger: LoggingService,
    private responseErrorPipe: ResponseErrorPipe,
    private translateService: TranslateService,
    private translationKeyService: TranslationKeyService,
    private monitoringService: MonitoringService,
    private store$: Store<RootStoreState.State>,
  ) {
    this.offlineSubject
      .asObservable()
      .pipe(throttleTime(2000))
      .subscribe(() => {
        this.lastOfflineAlert = this.alertService.error('Server not available', 'Error');
      });
    this.store$
      .pipe(select(AuthStoreSelectors.selectRefreshTokenLoading))
      .subscribe(loading => {
        this.refreshTokenLoading = loading;
      });
    this.store$
      .pipe(select(AuthStoreSelectors.selectRefreshLoginInProcess))
      .subscribe(loading => {
        this.refreshLoginInProcess = loading;
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        catchError(err => {
          this.monitoringService.logError(err);
          if (err instanceof HttpErrorResponse) {
            const msg = this.translateService.instant(this.responseErrorPipe.transform(err));
            let alert: Alert;
            if (this.showNoError(err) || req.headers.has('HideAlert')) {
              // throw no alert service error, but do continue the error throwing
            } else if (err.status >= 400 && err.status < 500) {
              alert = this.alertService.warn(msg, `${this.errorTranslation()} ${err.statusText}:`);
            } else if (err.status >= 500 && err.status < 600) {
              alert = this.alertService.error(msg, `${this.errorTranslation()} ${err.statusText}:`);
            } else if (err.status === 0) {
              alert = this.lastOfflineAlert;
              this.offlineSubject.next();
            }
            this.logger.error(err);
            return throwError(new HttpErrorAlert(err, alert));
          }
          return throwError(err);
        })
      );
  }

  private showNoError(err: HttpErrorResponse) {
    return err.error && err.error.error && err.error.error === 'invalid_grant' && this.refreshTokenLoading && !this.refreshLoginInProcess;
  }

  private errorTranslation(): string {
    return this.translationKeyService.translateInstant('COMMON', 'ERROR', 'ERROR');
  }
}
