export enum CssFeatures {
  // Header options
  HeaderFixed,	      // Fixed Header

  // Brand options
  BrandMinimized,     // Minimized brand (Only symbol)

  // Sidebar options
  SidebarFixed,				// Fixed Sidebar
  SidebarShow,			  // Show Sidebar
  SidebarOffCanvas,		// Off Canvas Sidebar
  SidebarMinimized,		// Minimized Sidebar (Only icons)
  SidebarCompact,			// Compact Sidebar

  // Aside options
  AsideMenuFixed,			// Fixed Aside Menu
  AsideMenuShow,		  // Show Aside Menu
  AsideMenuOffCanvas,	// Off Canvas Aside Menu

  // Breadcrumb options
  BreadcrumbFixed,		// Fixed Breadcrumb

  // Footer options
  FooterFixed,				// Fixed footer
}
