import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Injectable()
export class LoadingBarService {

  private prog = 0;
  private isVisible = false;
  private progressSubject = new BehaviorSubject<number>(this.prog);
  private visibleSubject = new BehaviorSubject<boolean>(this.isVisible);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private isEndless = false;
  private running = false;
  interval = 500;

  get endless(): boolean {
    return this.isEndless;
  }

  get visible$(): Observable<boolean> {
    return this.visibleSubject.asObservable();
  }

  set visible(value: boolean) {
    this.isVisible = value;
    this.visibleSubject.next(value);
  }

  get progress$(): Observable<number> {
    return this.progressSubject.asObservable();
  }

  get loading$(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  set loading(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  get progress(): number {
    return this.prog;
  }

  set progress(value: number) {
    if (value > 0) {
      this.visible = true;
    }
    this.prog = value;
    const emittedProgress = this.endless ? LoadingBarService.easeOutQuad(value / 100) * 100 : value;
    this.progressSubject.next(Math.min(emittedProgress, 100));
  }

  /**
   * decelerating to zero velocity
   * @param t time between 0 and 1
   */
  private static easeOutQuad(t: number): number {
    return t * (2 - t);
  }

  start() {
    // Stop currentLevel timer
    this.pause();
    this.isEndless = true;
    this.running = true;
    this.loading = true;
    // Make it visible for sure
    this.visible = true;
    // Run the timer with milliseconds intervall
    interval(this.interval)
      .pipe(
        takeWhile(() => this.running)
      )
      .subscribe(() => {
        // Increment the progress and update view component
        if (this.progress < 99) {
          this.progress += 0.25;
        }
      });
  }

  pause() {
    this.running = false;
  }

  reset() {
    this.pause();
    this.progress = 0;
    this.isEndless = false;
    this.loading = false;
  }

  complete(onCompleted: Function = null) {
    this.reset();
    if (onCompleted) {
      onCompleted();
    }
  }
}
