import { Component, HostBinding, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { CssFeatures } from '@css-features/css-features.enum';
import { CssFeaturesService } from '@css-features/css-features.service';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Store } from '@ngrx/store';
import { AuthStoreActions, RootStoreState, UserStoreActions } from '@app/root-store';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';
import { OverlayService } from '@shared/services/overlay/overlay.service';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  /* tslint:disable-next-line:component-selector */
  selector: '[opr-body]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  showOverlay = false;
  private classesSet: Set<CssFeatures>;
  private ngUnsubscribe = new Subject();

  constructor(
    private pageFeatureService: CssFeaturesService,
    private titleService: Title,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private store$: Store<RootStoreState.State>,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    private monitoringService: MonitoringService,
    private overlayService: OverlayService,
  ) {
    pageFeatureService.features$.subscribe(set => {
      this.classesSet = set;
    });
    this.titleService.setTitle(this.appConfig.brandName);
    this.store$.dispatch(new AuthStoreActions.AuthLoginFromStorageAction());

    this.overlayService.showOverlay$.subscribe(show => this.showOverlay = show);
  }

  ngOnInit() {
    this.monitoringService.user$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(userId => userId !== null)
      )
      .subscribe(() => {
        this.monitoringService.logScreenInfo(window.innerWidth, window.innerHeight);
      });

    this.store$.dispatch(new UserStoreActions.UserSetScreenWidthAction({ width: window.innerWidth }));

    if (window.innerWidth && window.innerWidth < 768) {
      this.pageFeatureService.hideSidebar();
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.store$.dispatch(new UserStoreActions.UserSetScreenWidthAction({ width: event.target.innerWidth }));
    if (event.target?.innerWidth > 768 && !this.pageFeatureService.sidebarVisible) {
      this.pageFeatureService.showSidebar();
    }
  }

  @HostBinding('class.header-fixed')
  get HeaderFixed(): boolean {
    return this.has(CssFeatures.HeaderFixed);
  }

  @HostBinding('class.brand-minimized')
  get BrandMinimized(): boolean {
    return this.has(CssFeatures.BrandMinimized);
  }

  @HostBinding('class.sidebar-fixed')
  get SidebarFixed(): boolean {
    return this.has(CssFeatures.SidebarFixed);
  }

  @HostBinding('class.sidebar-off-canvas')
  get SidebarOffCanvas(): boolean {
    return this.has(CssFeatures.SidebarOffCanvas);
  }

  @HostBinding('class.sidebar-minimized')
  get SidebarMinimized(): boolean {
    return this.has(CssFeatures.SidebarMinimized);
  }

  @HostBinding('class.sidebar-compact')
  get SidebarCompact(): boolean {
    return this.has(CssFeatures.SidebarCompact);
  }

  @HostBinding('class.aside-menu-fixed')
  get AsideMenuFixed(): boolean {
    return this.has(CssFeatures.AsideMenuFixed);
  }

  @HostBinding('class.aside-menu-show')
  get AsideMenuHidden(): boolean {
    return this.has(CssFeatures.AsideMenuShow);
  }

  @HostBinding('class.aside-menu-off-canvas')
  get AsideMenuOffCanvas(): boolean {
    return this.has(CssFeatures.AsideMenuOffCanvas);
  }

  @HostBinding('class.breadcrumb-fixed')
  get BreadcrumbFixed(): boolean {
    return this.has(CssFeatures.BreadcrumbFixed);
  }

  @HostBinding('class.footer-fixed')
  get FooterFixed(): boolean {
    return this.has(CssFeatures.FooterFixed);
  }

  @HostBinding('class.sidebar-show')
  get SidebarMobileShow(): boolean {
    return this.has(CssFeatures.SidebarShow);
  }

  private has(feature: CssFeatures) {
    return this.classesSet && this.classesSet.has(feature);
  }

}
