import { UserViewModel } from '@circlon/operate-api-model';

export interface State {
  user: UserViewModel;
  loading: boolean;
  loaded: boolean;
  error: any;
  passwordResetSuccess: boolean;
  passwordResetLoading: boolean;
  passwordResetError: any;
  setNewPasswordError: any;
  screenWidth: number;
}

export const initialState: State = {
  user: null,
  loading: false,
  loaded: false,
  error: null,
  passwordResetSuccess: false,
  passwordResetLoading: false,
  passwordResetError: null,
  setNewPasswordError: null,
  screenWidth: null,
};
