import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CssFeatures } from './css-features.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoggingService } from '@shared/services/logging/logging.service';

export const PAGE_FEATURES_DEFAULTS = new InjectionToken<Set<CssFeatures> | CssFeatures[]>('Set of page features enabled by default');

@Injectable()
export class CssFeaturesService {

  private readonly activeFeatures: Set<CssFeatures>;
  private activeFeaturesSubject: BehaviorSubject<Set<CssFeatures>>;

  constructor(
    @Inject(PAGE_FEATURES_DEFAULTS) defaults: Set<CssFeatures>,
    private logger: LoggingService,
  ) {
    this.activeFeatures = new Set<CssFeatures>(defaults);
    this.activeFeaturesSubject = new BehaviorSubject<Set<CssFeatures>>(this.activeFeatures);
    this.publishFeatures();
  }

  get features$(): Observable<Set<CssFeatures>> {
    return this.activeFeaturesSubject.asObservable();
  }

  get sidebarVisible(): boolean {
    return this.activeFeatures.has(CssFeatures.SidebarShow);
  }

  showSidebar() {
    this.activeFeatures.add(CssFeatures.SidebarShow);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'showSidebar()');
  }

  hideSidebar() {
    this.activeFeatures.delete(CssFeatures.SidebarShow);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'hideSidebar()');
  }

  toggleSidebar() {
    if (this.sidebarVisible) {
      this.hideSidebar();
    } else {
      this.showSidebar();
    }
  }

  get asideMenuVisible(): boolean {
    return this.activeFeatures.has(CssFeatures.AsideMenuShow);
  }

  showAsideMenu() {
    this.activeFeatures.add(CssFeatures.AsideMenuShow);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'showAsideMenu()');
  }

  hideAsideMenu() {
    this.activeFeatures.delete(CssFeatures.AsideMenuShow);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'hideAsideMenu()');
  }

  toggleAsideMenu() {
    if (this.asideMenuVisible) {
      this.hideAsideMenu();
    } else {
      this.showAsideMenu();
    }
  }

  get brandMinimized() {
    return this.activeFeatures.has(CssFeatures.BrandMinimized);
  }

  minimizeBrand() {
    this.activeFeatures.add(CssFeatures.BrandMinimized);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'minimizeBrand()');
  }

  maximizeBrand() {
    this.activeFeatures.delete(CssFeatures.BrandMinimized);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'maximizeBrand()');
  }

  toggleMinimizeBrand() {
    if (this.brandMinimized) {
      this.maximizeBrand();
    } else {
      this.minimizeBrand();
    }
  }

  get sidebarMinimized() {
    return this.activeFeatures.has(CssFeatures.SidebarMinimized);
  }

  minimizeSidebar() {
    this.activeFeatures.add(CssFeatures.SidebarMinimized);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'minimizeSidebar()');
  }

  maximizeSidebar() {
    this.activeFeatures.delete(CssFeatures.SidebarMinimized);
    this.publishFeatures();
    this.logger.debug('CssFeatureService', 'maximizeSidebar()');
  }

  toggleMinimizeSidebar() {
    if (this.sidebarMinimized) {
      this.maximizeSidebar();
    } else {
      this.minimizeSidebar();
    }
  }

  private publishFeatures() {
    this.activeFeaturesSubject.next(this.activeFeatures);
  }

}
