import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { appVersion } from '@app/app.version';
import { environmentLoader } from '@environments/environment-loader';

environmentLoader
  .then(response => JSON.parse(response))
  .then((config: AppConfig) => {
    if (environment.production) {
      enableProdMode();
    }

    platformBrowserDynamic([
        {
          provide: APP_CONFIG,
          useValue: { ...config, version: appVersion } as AppConfig
        }])
      .bootstrapModule(AppModule)
      .catch(err => console.log(err));
  });
