import { Injectable } from '@angular/core';
import { GridFilterMethodEnumeration, GridFilterTenantViewModel } from '@circlon/operate-api-model';
import { GridfilterDataService } from '../../gridfilter-data';
import { GridfilterRequestService } from '../../gridfilter-request.service';

@Injectable({
  providedIn: 'root'
})
export class TenantListDataService extends GridfilterDataService<GridFilterTenantViewModel> {
  constructor(requestService: GridfilterRequestService) {
    super(requestService, GridFilterMethodEnumeration.TreeFilterTenantCommand);
  }
}
