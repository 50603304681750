import { Inject, Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { ReplaySubject } from 'rxjs';

export enum RmaMonitoringEvent {
  RMA_DEVICE_DEFECT_CLICKED,
  RMA_GENERATE_CLICKED,
}

@Injectable({
  providedIn: 'root'
})
export class MonitoringService {

  private config: Microsoft.ApplicationInsights.IConfig = {
    instrumentationKey: this.appConfig.aiKey,
  };

  user$ = new ReplaySubject();

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) {
    if (!AppInsights.config) {
      AppInsights.downloadAndSetup(this.config);
    }
  }

  logPageView(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
    duration?: number
  ) {
    AppInsights.trackPageView(name, url, this.AddGlobalProperties(properties), measurements, duration);
  }

  logPageStart(name: string) {
    AppInsights.startTrackPage(name);
  }

  logPageEnd(
    name: string,
    url?: string,
    properties?: { [key: string]: string },
    measurements?: { [key: string]: number },
  ) {
    AppInsights.stopTrackPage(name, url, this.AddGlobalProperties(properties), measurements);
  }

  logEvent(name: string, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackEvent(name, this.AddGlobalProperties(properties), measurements);
  }

  logRmaEvent(event: RmaMonitoringEvent, assetId: number, measurements?: { [key: string]: number }) {
    this.logEvent(RmaMonitoringEvent[event], { assetId: assetId.toString(10) }, measurements);
  }

  logError(error: Error, properties?: { [key: string]: string }, measurements?: { [key: string]: number }) {
    AppInsights.trackException(error, null, this.AddGlobalProperties(properties), measurements);
  }

  logScreenInfo(width, height) {
    this.logEvent('USER_SCREEN_INFO', {width, height});
  }

  setAuthenticatedUserId(userId: number, roleName?: string) {
    const telemetryInitializer = () => {
      AppInsights.setAuthenticatedUserContext(userId.toString(10));
      AppInsights.context.addTelemetryInitializer((envelope => {
        const tags = envelope.tags;
        if (tags && tags['ai.user.authUserId']) {
          tags['ai.user.id'] = tags['ai.user.authUserId'];
          if (roleName) {
            envelope.tags['ai.cloud.role'] = roleName;
          }
        }
      }));
      this.user$.next(userId);
    };

    if (AppInsights.queue !== undefined) {
      AppInsights.queue.push(telemetryInitializer);
    } else {
      telemetryInitializer();
    }
  }

  clearUserId() {
    AppInsights.clearAuthenticatedUserContext();
    this.user$.next(null);
  }

  private AddGlobalProperties(properties?: { [key: string]: string }): { [key: string]: string } {
    if (!properties) {
      properties = {};
    }

    // add your custom properties such as app version
    properties = {
      ...properties,
      environment: this.appConfig.name,
      version: this.appConfig.version,
    };

    return properties;
  }
}
