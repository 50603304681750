import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AuthStoreModule } from '@app/root-store/auth-store';
import { UserStoreModule } from '@app/root-store/user-store';
import { ImpersonationStoreModule } from '@app/root-store/impersonation-store';
import { environment } from '@environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStoreModule } from '@app/root-store/router-store';

@NgModule({
  declarations: [],
  imports: [
    AuthStoreModule,
    UserStoreModule,
    ImpersonationStoreModule,
    RouterStoreModule,
    StoreModule.forRoot({}, {
      runtimeChecks: {
        // TODO: set strict state checks to true once all places are known
        strictStateImmutability: false,
        strictActionImmutability: false,
      }
    }),
    EffectsModule.forRoot([]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
        maxAge: 15,
        name: 'Operate NgRx Monitor',
      })
  ]
})
export class RootStoreModule { }
