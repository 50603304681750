import { GridFilterComparisonEnumeration } from '@circlon/operate-api-model';

export enum FilterFieldType {
  BASIC,
  DATE,
}

export interface FilterField {
  model?: any[];
  activeComparison: GridFilterComparisonEnumeration;
  property: string;
  type?: FilterFieldType;
}
