import { Inject, Injectable } from '@angular/core';

import { LOG_LEVEL } from './logging.service';
import { Logging } from './logging.model';
import { LoggingLevel } from './logging-level.enum';

@Injectable()
export class ConsoleLoggingService implements Logging {

  constructor(@Inject(LOG_LEVEL) public logLevel: LoggingLevel) {
  }

  debug(...msg: any[]) {
    if (this.logLevel === LoggingLevel.Debug) {
      return console.debug.apply(console, msg);
    }
  }

  info(...msg: any[]) {
    if (this.logLevel <= LoggingLevel.Info) {
      return console.info.apply(console, msg);
    }
  }

  warn(...msg: any[]) {
    if (this.logLevel <= LoggingLevel.Warn) {
      return console.warn.apply(console, msg);
    }
  }

  error(...msg: any[]) {
    if (this.logLevel <= LoggingLevel.Error) {
      return console.error.apply(console, msg);
    }
  }
}
