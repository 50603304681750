import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@app/root-store';
import {
  ActivatedRoute,
  ActivationStart,
  NavigationEnd,
  NavigationStart,
  ResolveStart,
  Router,
  RouterEvent
} from '@angular/router';
import * as routerActions from './router.actions';
import { filter, withLatestFrom } from 'rxjs/operators';
import { CollapseService } from '@shared/services/collapse/collapse.service';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';
import { RouteKeyService } from '@shared/services/routes/route-key.service';
import { selectFilter } from './router.selectors';

interface PageInfo {
  title: string;
  url: string;
  params: { [key: string]: string };
}

@Injectable({
  providedIn: 'root'
})
export class RouterStoreEffects {

  currentPage: PageInfo;

  constructor(
    private actions$: Actions,
    private store$: Store<RootStoreState.State>,
    private router: Router,
    private route: ActivatedRoute,
    private collapseService: CollapseService,
    private monitoringService: MonitoringService,
  ) {
    this.listenToRouter();
  }

  private listenToRouter() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart),
        withLatestFrom(this.store$.select(selectFilter))
      )
      .subscribe(([event, savedFilter]) => {
        event = event as NavigationStart;
        this.endPageView();
        this.store$.dispatch(new routerActions.RouterResetNewRouteAction());
        if (savedFilter && savedFilter.route) {
          let savedRoute = '';
          savedFilter.route.forEach(routePart => savedRoute += '/' + routePart);
          if (savedRoute !== event.url) {
            this.store$.dispatch(new routerActions.ClearFilter());
          }
        }
      });

    this.router.events.pipe(
      filter(event => event instanceof ActivationStart),
    ).subscribe((event: ActivationStart) => {
      // parent routes have no params. ActivationStart fires on every level of the route _/asset/123/detail -> 4 x
      if (event.snapshot.data && Object.keys(event.snapshot.params).length === 0 && event.snapshot.params.constructor === Object) {
        // filter out the '' empty route
        if (event.snapshot.routeConfig.path !== '') {
          if (event.snapshot.data.translation) {
            this.store$.dispatch(new routerActions.RouterSetTranslationGroupsAction({ groups: event.snapshot.data.translation }));
          }
          this.store$.dispatch(new routerActions.RouterAddNewRouteAction({route: event.snapshot.routeConfig.path}));
        }
        this.store$.dispatch(new routerActions.RouterSetCurrentParentActivationAction({activation: event.snapshot.url}));
      }
    });

    // collapse
    this.router.events
      .pipe(filter(event => event instanceof ResolveStart))
      .subscribe((event: ResolveStart) => {
        if (!this.isMainRoute(event)) {
          this.collapseService.detailCardCollapsed = false;
        }
      });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.store$.dispatch(new routerActions.RouterSetCurrentRouteAction());
        if (this.isMainRoute(event)) {
          this.collapseService.detailCardCollapsed = true;
        }

        this.trackPageView();
      });
  }

  private isMainRoute(event: RouterEvent): boolean {
    const urlSegments = event.url.split('/').filter(segment => segment !== '');
    const createIndex = urlSegments.findIndex(segment => segment === RouteKeyService.create);
    return urlSegments.length < 3 && createIndex === -1;
  }

  private trackPageView() {
    let activeRoute: ActivatedRoute = this.route;

    while (activeRoute.firstChild) {
      activeRoute = activeRoute.firstChild;
    }

    if (activeRoute && activeRoute.outlet === 'primary' && activeRoute.snapshot.data.title) {
      this.currentPage = {
        title: activeRoute.snapshot.data.title,
        url: this.router.url,
        params: activeRoute.snapshot.params,
      };
      this.monitoringService.logPageStart(this.currentPage.title);
    }
  }

  private endPageView() {
    if (this.currentPage) {
      this.monitoringService.logPageEnd(this.currentPage.title, this.currentPage.url, this.currentPage.params);
      this.currentPage = undefined;
    }
  }

}
