import { Injectable } from '@angular/core';
import { PermissionKeyService } from '@shared/services/permission/permission-key.service';
import { select, Store } from '@ngrx/store';
import { UserStoreSelectors } from '@app/root-store';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  permissions: string[] = [];

  constructor(
    private store$: Store<{}>
  ) {
    this.store$
      .pipe(
        select(UserStoreSelectors.selectUserPermissions),
        filter(permissions => permissions != null)
      )
      .subscribe(permissions => {
        this.permissions = permissions;
      });
  }

  canAccess(permission: string): boolean {
    if (permission.length === 0) {
      return true;
    }
    const filtered = this.permissions.filter(p => p === permission);
    return filtered.length === 1;
  }

  canAccessAll(givenPermissions: string[]) {
    const filtered = this.permissions.filter(p => {
      if (givenPermissions.filter(given => given === p).length === 1) {
        return p;
      }
    });
    return filtered.length === givenPermissions.length;
  }

  canAccessAny(givenPermissions: string[]) {
    const filtered = this.permissions.filter(p => {
      if (givenPermissions.filter(given => given === p).length === 1) {
        return p;
      }
    });
    return filtered.length > 0;
  }

  permissionAsideView() {
    const allPermissions = [...PermissionKeyService.asideViewAsset]
      .concat(PermissionKeyService.asideViewRma, PermissionKeyService.asideViewUser);
    return this.canAccessAny(allPermissions);
  }

  permissionAsideViewAsset() {
    return this.canAccessAny(PermissionKeyService.asideViewAsset);
  }

  permissionAsideViewRma() {
    return this.canAccessAny(PermissionKeyService.asideViewRma);
  }

  permissionAsideViewUser() {
    return this.canAccessAll(PermissionKeyService.asideViewUser);
  }

}
