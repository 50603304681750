import { Injectable } from '@angular/core';
import { GridFilterI18nViewModel } from '@circlon/operate-api-model';

@Injectable()
export class I18nMapperService {

  listToDictionary(list: GridFilterI18nViewModel[]): {} {
    return list.reduce((result, i18n) => {
        if (result[i18n.group]) {
          Object.assign(result[i18n.group], {[i18n.key]: i18n.translation});
        } else {
          Object.assign(result, {[i18n.group]: {[i18n.key]: i18n.translation}});
        }
        return result;
      }, {}
    );
  }
}
