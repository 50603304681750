import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { SessionState } from '../session/session-state';
import { LoggingService } from '../logging/logging.service';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors, RootStoreState } from '@app/root-store';

@Injectable()
export class SetPasswordGuard implements CanActivate {
  private sessionState: SessionState;

  constructor(private logger: LoggingService,
              private router: Router,
              private store$: Store<RootStoreState.State>,
  ) {
    this.store$.pipe(select(AuthStoreSelectors.selectSessionState))
      .subscribe(sessionState => this.sessionState = sessionState);
  }

  canActivate() {
    this.logger.debug('SetPasswordGuard canActivate() called');
    if (SessionState.NeedsPasswordChange === this.sessionState) {
      return true;
    }
    this.logger.debug('SetPasswordGuard: Access denied. Already logged in - Redirect');
    this.router.navigate(['/']);
    return false;
  }
}
