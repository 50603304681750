import { Actions, ActionTypes } from './auth.actions';
import { initialState } from './auth.state';

export function featureReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.AUTH_LOGIN: {
      return {
        ...state,
        loading: true,
        loginProcessDone: false,
        loginFromStorageProcessDone: true,
        error: null,
      };
    }

    case ActionTypes.AUTH_LOGOUT: {
      return {
        ...initialState,
      };
    }

    case ActionTypes.AUTH_LOGIN_FROM_STORAGE: {
      return {
        ...state,
        initializing: true,
        loginFromStorageProcessDone: false,
      };
    }

    case ActionTypes.AUTH_SUCCESS:
    case ActionTypes.AUTH_EXPIRED_LOGIN_SUCCESS:
    case ActionTypes.AUTH_EXPIRED_LOGIN_REROUTE_NEEDED: {
      return {
        ...state,
        oauth: action.payload.auth,
        sessionState: action.payload.sessionState,
        loading: false,
        refreshLoginInProcess: false,
        error: null,
      };
    }

    case ActionTypes.AUTH_FAILURE:
    case ActionTypes.AUTH_EXPIRED_LOGIN_FAILURE: {
      return {
        ...state,
        loading: false,
        refreshLoginInProcess: false,
        error: action.payload.error
      };
    }

    case ActionTypes.AUTH_LOGIN_SUCCESS_FROM_STORAGE: {
      return {
        ...state,
        initializing: false,
        oauth: action.payload.auth,
        sessionState: action.payload.sessionState,
        loginProcessDone: true,
      };
    }

    case ActionTypes.AUTH_REFRESH: {
      return {
        ...state,
        refreshTokenLoading: true,
      };
    }

    case ActionTypes.AUTH_REFRESH_SUCCESS: {
      return {
        ...state,
        oauth: action.payload.auth,
        refreshTokenLoading: false,
      };
    }

    case ActionTypes.AUTH_EXPIRED_SHOW_LOGIN: {
      return {
        ...state,
        refreshTokenLoading: false,
      };
    }

    case ActionTypes.AUTH_REFRESH_FAILURE: {
      return {
        ...state,
        refreshTokenLoading: false,
      };
    }

    case ActionTypes.AUTH_EXPIRED_LOGIN: {
      return {
        ...state,
        refreshLoginInProcess: true,
      };
    }

    case ActionTypes.AUTH_REDIRECT: {
      return {
        ...state,
        loginProcessDone: true,
      };
    }

    default: {
      return state;
    }
  }
}
