import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

import { LoadingBarService } from '../../services/loading-bar/loading-bar.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'opr-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
})
export class LoadingBarComponent implements OnInit, OnDestroy {
  progress = 0;
  show = false;
  transition = true;
  loading = true;

  private ngUnsubscribe = new Subject();

  constructor(private loadingBarService: LoadingBarService) {
  }

  ngOnInit() {
    this.loadingBarService.progress$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(next => {
        this.transition = next >= this.progress;
        this.progress = next;
      });

    this.loadingBarService.visible$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(next => {
        this.show = next;
      });

    this.loadingBarService.loading$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(loading => {
        this.loading = loading;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
