import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OverlayService {

  private showing = new BehaviorSubject<boolean>(false);

  get showOverlay$(): Observable<boolean> {
    return this.showing.asObservable();
  }

  setShowOverlay(show: boolean) {
    this.showing.next(show);
  }
}
