import { Injectable } from '@angular/core';
import { ActiveFilter } from './active-filter.model';
import { FilterField, FilterFieldType } from './filter-field.model';
import { GridFilterComparisonEnumeration } from '@circlon/operate-api-model';

@Injectable({
  providedIn: 'root'
})
export class SearchParamBuilderService {

  fromFilterFields(fields: FilterField[], linkOr: boolean = false): any {
    this.cleanFilterFields(fields);
    let searchParams: any = {};
    const activeFilter = [];
    fields
      .filter(field => field.model && field.model.length)
      .forEach(filter => {
        if (filter.type === FilterFieldType.DATE) {
          const startDate = new ActiveFilter({
            property: filter.property,
            model: [new Date(new Date(filter.model[0]).setHours(0, 0, 0, 0))],
            activeComparison: GridFilterComparisonEnumeration.GreaterOrEqual,
          });

          // if there is one date in model search start to end of day
          // if there are two dates in model use start of first date and end of second date
          const endDateModel = filter.model.length > 1 ? filter.model[1] : filter.model[0];

          const endDate = new ActiveFilter({
            property: filter.property,
            model: [new Date(new Date(endDateModel).setHours(23, 59, 59, 999))],
            activeComparison: GridFilterComparisonEnumeration.LessOrEqual,
          });

          activeFilter.push(startDate);
          activeFilter.push(endDate);
          return;
        }
        activeFilter.push(new ActiveFilter(filter));
      });

    if (activeFilter.length === 1) {
      searchParams = activeFilter[0].params;
    } else if (activeFilter.length > 1) {
      searchParams[linkOr ? 'or' : 'and'] = activeFilter.map(filterField => filterField.params);
    }

    return searchParams;
  }

  private cleanFilterFields(fields: FilterField[]) {
    fields.forEach(field => {
      if (field.model && !Array.isArray(field.model)) {
        field.model = [field.model];
      }
    });
  }

}
