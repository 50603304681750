import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ImpersonationStoreEffects } from './impersonation.effects';
import { featureReducer } from './impersonation.reducer';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('impersonation', featureReducer),
    EffectsModule.forFeature([ImpersonationStoreEffects])
  ]
})
export class ImpersonationStoreModule { }
