import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {
  GridFilterMethodEnumeration,
  GridFilterRequestPropertiesViewModel,
  IGridFilterRequest,
  IGridFilterResponse
} from '@circlon/operate-api-model';
import { BehaviorSubject, Observable, ReplaySubject, throwError } from 'rxjs';
import { GenericGridFilterResponse } from './generic-grid-filter-response';
import { Guid } from '@app/utils/guid';
import { catchError, debounceTime, filter, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { ExportTypes } from '@shared/enums/export-types-enum';

@Injectable({
  providedIn: 'root'
})
export class GridfilterRequestService {

  private requestSubject = new BehaviorSubject<IGridFilterRequest>(null);
  private requestBuffer = new Map<string, IGridFilterRequest>();
  private responseQueue = new Map<string, ReplaySubject<IGridFilterResponse>>();
  private baseUrl = `${this.appConfig.apiBaseEndpoint}v1.0/gridfilter`;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.requestSubject
      .pipe(
        filter(req => req !== null),
        tap(req => this.requestBuffer.set(req.id, req)),
        debounceTime(50)
      )
      .subscribe(() => {
        this.sendRequest(Array.from(this.requestBuffer.values()));
        this.requestBuffer.clear();
      });
  }

  getRequestProperties(method: GridFilterMethodEnumeration): Observable<GridFilterRequestPropertiesViewModel> {
    const params = new HttpParams().set('Method', GridFilterMethodEnumeration[method]);
    return this.httpClient.get(this.baseUrl, {params: params}) as Observable<GridFilterRequestPropertiesViewModel>;
  }

  requestList(payload: IGridFilterRequest, instant: boolean = false): Observable<GenericGridFilterResponse<any>> {
    const subject = new ReplaySubject<IGridFilterResponse>();
    payload.id = Guid.newGuid();
    this.responseQueue.set(payload.id, subject);
    if (instant) {
      this.sendRequest(payload);
    } else {
      this.requestSubject.next(payload);
    }
    return subject.asObservable() as Observable<GenericGridFilterResponse<any>>;
  }

  requestExport(payload: IGridFilterRequest, fileName: string): Promise<boolean> {
    const subject = new ReplaySubject<IGridFilterResponse>();
    payload.id = Guid.newGuid();
    this.responseQueue.set(payload.id, subject);

    return this.httpClient.post<Blob>(
      payload.params.gridFilterExport === ExportTypes.MINDEN ? `${this.baseUrl}/exportMinden` : `${this.baseUrl}/export`,
      payload,
      {responseType: 'blob' as 'json', observe: 'response'})
      .toPromise().then(resp => {
        let fileNameUsed = this.extractFileName(resp.headers);
        if (!fileNameUsed) {
          fileNameUsed = fileName;
        }

        if (resp.body.type === 'text/csv') {
          fileNameUsed += '.csv';
        } else if (resp.body.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          fileNameUsed += '.xlsx';
        }

        saveAs(resp.body, fileNameUsed);
        return resp.body;
    }).then(() => Promise.resolve(true));
  }

  private sendRequest(payload: IGridFilterRequest | IGridFilterRequest[]) {
    let payloads = Array.isArray(payload) ? payload : [payload];
    payloads = payloads.filter(pl => !!this.responseQueue.get(pl.id).observers.length);
    this.httpClient.post(this.baseUrl, payload)
      .pipe(
        catchError(err => {
          payloads.forEach(pay => {
            const sub = this.responseQueue.get(pay.id);
            sub.error(err);
            this.responseQueue.delete(pay.id);
            sub.complete();
          });
          return throwError(err);
        })
      )
      .subscribe((responses: IGridFilterResponse[]) => {
        responses.forEach(response => {
          const sub = this.responseQueue.get(response.id);
          if (response.error) {
            sub.error(response.error);
          } else {
            sub.next(response);
          }
          this.responseQueue.delete(response.id);
          sub.complete();
        });
      });
  }

  private extractFileName(headers: HttpHeaders): string {
    const disposition = headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disposition);
      if (match != null && match[1]) {
        return match[1].replace(/['"]/g, '');
      }
    }
    return '';
  }
}
