import { initialState } from './user.state';
import { Actions, ActionTypes } from './user.actions';
import { UserViewModel } from '@circlon/operate-api-model';

export function featureReducer(state = initialState, action: Actions) {
  switch (action.type) {
    case ActionTypes.USER_LOGOUT:
      return {
        ...state,
        user: null,
        loading: false,
        loaded: false,
        error: null,
        passwordResetSuccess: false,
        passwordResetLoading: false,
        passwordResetError: null,
      };

    case ActionTypes.USER_LOAD:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ActionTypes.USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        loading: false,
        loaded: true,
      };

    case ActionTypes.USER_UPDATE:
      return {
        ...state,
        user: action.payload.user,
      };

    case ActionTypes.USER_PROFILE_UPDATE: {
      return {
        ...state,
        user: UserViewModel.fromJS({ ...state.user, ...action.payload.user  })
      };
    }

    case ActionTypes.USER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loaded: false,
        loading: false,
      };

    case ActionTypes.USER_RESET_PASSWORD:
      return {
        ...state,
        passwordResetLoading: true,
        passwordResetSuccess: false,
        passwordResetError: null,
      };

    case ActionTypes.USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordResetSuccess: true,
        passwordResetLoading: false,
        passwordResetError: null,
      };

    case ActionTypes.USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        passwordResetLoading: false,
        passwordResetError: action.payload.error,
      };

    case ActionTypes.USER_RESET_PASSWORD_RESET:
      return {
        ...state,
        passwordResetSuccess: false,
        passwordResetLoading: false,
        passwordResetError: null,
      };

    case ActionTypes.USER_SET_NEW_PASSWORD:
    case ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED:
      return {
        ...state,
        setNewPasswordError: null,
      };

    case ActionTypes.USER_SET_NEW_PASSWORD_FAILURE:
    case ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED_FAILURE:
      return {
        ...state,
        setNewPasswordError: action.payload.error,
      };

    case ActionTypes.USER_SET_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: action.payload.width,
      };

    case ActionTypes.USER_CLEAR_SCREEN_WIDTH:
      return {
        ...state,
        screenWidth: null,
      };

    default: {
      return state;
    }
  }
}
