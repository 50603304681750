import { UrlSegment } from '@angular/router';
import { GridSearchFilterField } from '@operate/ui/data-grid-models';

export interface RouterFilter {
  route: string[];
  filter: GridSearchFilterField[];
}

export interface State {
  currentParentActivationUrl: UrlSegment[];
  currentRoute: string[];
  newRoute: string[];
  translationGroups: string[];
  filter: RouterFilter;
}

export const initialState: State = {
  currentParentActivationUrl: [],
  currentRoute: [],
  newRoute: [],
  translationGroups: [],
  filter: null,
};
