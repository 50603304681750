import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Alert, AlertType } from './alert.model';
import { filter } from 'rxjs/operators';

@Injectable()
export class AlertService {
  private alerts = new Set<Alert>();
  private alertsSubject = new Subject<Set<Alert>>();

  get alerts$(): Observable<Set<Alert>> {
    return this.alertsSubject.asObservable();
  }

  get alertSet(): Set<Alert> {
    return this.alerts;
  }

  success(message: string, title?: string, html?: string, timeout?: number): Alert {
    return this.alert(AlertType.Success, message, title, html, timeout);
  }

  error(message: string, title?: string, html?: string, timeout?: number): Alert {
    return this.alert(AlertType.Error, message, title, html, timeout);
  }

  info(message: string, title?: string, html?: string, timeout?: number): Alert {
    return this.alert(AlertType.Info, message, title, html, timeout);
  }

  warn(message: string, title?: string, html?: string, timeout?: number): Alert {
    return this.alert(AlertType.Warning, message, title, html, timeout);
  }

  alert(type: AlertType, message: string, title?: string, html?: string, timeout?: number): Alert {
    return this.addAlert(new Alert(type, message, title, html, timeout));
  }

  clearAll() {
    this.alerts.forEach(x => x.dismiss());
  }

  private addAlert(alert: Alert): Alert {
    this.removeAlertsOfSameType(alert);
    this.alerts.add(alert);
    alert.dismissed$
      .pipe(filter(dm => dm))
      .subscribe(() => {
        this.removeAlert(alert);
      });
    this.alertsSubject.next(this.alerts);
    return alert;
  }

  private removeAlert(alert: Alert): Alert {
    this.alerts.delete(alert);
    return alert;
  }

  private removeAlertsOfSameType(alert: Alert) {
    const dismissAll: Alert[] = [];
    this.alerts.forEach(activeAlert => {
      if (activeAlert.type === alert.type && activeAlert.heading === alert.heading && activeAlert.message === alert.message) {
        dismissAll.push(activeAlert);
      }
    });
    dismissAll.forEach(activeAlert => this.removeAlert(activeAlert));
  }
}
