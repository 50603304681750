import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ContextTargetData } from '@circlon/operate-api-model';

export const featureAdapter: EntityAdapter<ContextTargetData>
  = createEntityAdapter<ContextTargetData>({
  selectId: model => model.tenantId,
  sortComparer: (a: ContextTargetData, b: ContextTargetData): number =>
    a.tenantId.toString().localeCompare(b.tenantId.toString())
});

export interface State extends EntityState<ContextTargetData> {
  impersonated?: ContextTargetData;
  loading?: boolean;
  loaded?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  loading: false,
  loaded: false,
  impersonated: null,
  error: null
});
