import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SessionState } from '../session/session-state';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors, ImpersonationStoreSelectors, RootStoreState } from '@app/root-store';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private token: string;
  private authenticated: boolean;
  private tenantId: number;

  constructor(
    private store$: Store<RootStoreState.State>,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    this.store$.pipe(select(AuthStoreSelectors.selectAuth))
      .subscribe(auth => {
        this.token = auth ? auth.access_token : null;
      });

    this.store$.pipe(select(ImpersonationStoreSelectors.selectImpersonationTarget))
      .subscribe(target => {
        this.tenantId = target ? target.tenantId : null;
      });

    this.store$.pipe(select(AuthStoreSelectors.selectSessionState))
      .subscribe(curSessState => {
        this.authenticated = [
          SessionState.NeedsPasswordChange,
          SessionState.PasswordExpired,
          SessionState.LoggedIn,
        ].includes(curSessState);
      });
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // No token needed
    if (!this.authenticated || !this.routeNeedsAuth(request.url)) {
      return next.handle(request);
    }
    if (request.method === 'GET') {
      request = request.clone({
        setHeaders: this.buildHeaders(),
        headers: request.headers
          .set('Cache-Control', 'no-cache')
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
      });
    } else {
      request = request.clone({
        setHeaders: this.buildHeaders(),
      });
    }
    return next.handle(request);
  }

  private routeNeedsAuth(url: string): boolean {
    return url.startsWith(this.appConfig.apiBaseEndpoint)
      && !url.startsWith('oauth/', this.appConfig.apiBaseEndpoint.length);
  }

  private buildHeaders(): { [name: string]: string | string[] } {

    const headers: { [name: string]: string | string[] } = {
      Authorization: `Bearer ${this.token}`,
    };

    if (this.tenantId != null) {
      headers.tenantId = this.tenantId.toString(10);
    }
    return headers;
  }
}
