import { Action } from '@ngrx/store';
import { UrlSegment } from '@angular/router';
import { RouterFilter } from './router.state';

export enum ActionTypes {
  ROUTER_SET_CURRENT_ROUTE = '[Router] Set current route',
  ROUTER_RESET_NEW_ROUTE = '[Router] Reset new route',
  ROUTER_ADD_TO_NEW_ROUTE = '[Router] Add to new route',
  ROUTER_SET_CURRENT_PARENT_ACTIVATION = '[Router] Set current parent activation',
  ROUTER_SET_TRANSLATION_GROUPS = '[Router] Set current translation group data',
  ROUTER_SET_FILTER = '[Router] Set filter for new route',
  ROUTER_CLEAR_FILTER = '[Router] Clear filter',
}

export class RouterSetCurrentRouteAction implements Action {
  readonly type = ActionTypes.ROUTER_SET_CURRENT_ROUTE;
}

export class RouterResetNewRouteAction implements Action {
  readonly type = ActionTypes.ROUTER_RESET_NEW_ROUTE;
}

export class RouterAddNewRouteAction implements Action {
  readonly type = ActionTypes.ROUTER_ADD_TO_NEW_ROUTE;

  constructor(public payload: { route: string }) {}
}

export class RouterSetCurrentParentActivationAction implements Action {
  readonly type = ActionTypes.ROUTER_SET_CURRENT_PARENT_ACTIVATION;

  constructor(public payload: { activation: UrlSegment[] }) {}
}

export class RouterSetTranslationGroupsAction implements Action {
  readonly type = ActionTypes.ROUTER_SET_TRANSLATION_GROUPS;
  constructor(public payload: { groups: string[] }) {}
}

export class SetFilter implements Action {
  readonly type = ActionTypes.ROUTER_SET_FILTER;

  constructor(public payload: RouterFilter) {}
}

export class ClearFilter implements Action {
  readonly type = ActionTypes.ROUTER_CLEAR_FILTER;
}

export type RouterActions =
  RouterSetCurrentRouteAction
  | RouterResetNewRouteAction
  | RouterAddNewRouteAction
  | RouterSetCurrentParentActivationAction
  | RouterSetTranslationGroupsAction
  | SetFilter
  | ClearFilter;
