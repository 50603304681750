import { State } from './user.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { UserViewModel } from '@circlon/operate-api-model';

export const getUserLoading = (state: State): boolean => state.loading;
export const getUserLoaded = (state: State): boolean => state.loaded;
export const getUser = (state: State): UserViewModel => state.user;
export const getUserPermissions = (state: State): string[] => state.user?.operations;
export const getPasswordResetSuccess = (state: State): boolean => state.passwordResetSuccess;
export const getPasswordResetError = (state: State): any => state.passwordResetError;
export const getSetNewPasswordError = (state: State): any => state.setNewPasswordError;
const getScreenWidth = (state: State): number => state.screenWidth;

export const selectUserState: MemoizedSelector<object, State>
  = createFeatureSelector<State>('user');

export const selectUserLoading: MemoizedSelector<Object, boolean> = createSelector(selectUserState, getUserLoading);

export const selectUserLoaded: MemoizedSelector<Object, boolean> = createSelector(selectUserState, getUserLoaded);

export const selectUser: MemoizedSelector<Object, UserViewModel> = createSelector(selectUserState, getUser);

export const selectUserPermissions: MemoizedSelector<Object, string[]> = createSelector(selectUserState, getUserPermissions);

export const selectPasswordResetSuccess: MemoizedSelector<Object, boolean> = createSelector(selectUserState, getPasswordResetSuccess);

export const selectPasswordResetError: MemoizedSelector<Object, any> = createSelector(selectUserState, getPasswordResetError);

export const selectSetNewPasswordError: MemoizedSelector<Object, any> = createSelector(selectUserState, getSetNewPasswordError);

export const selectScreenWidth: MemoizedSelector<Object, number> = createSelector(selectUserState, getScreenWidth);
