import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GridFilterRequestPropertiesViewModel } from '@circlon/operate-api-model';
import { Action, Store } from '@ngrx/store';
import { ParamData } from './param-data.model';
import { GridfilterDataService } from './gridfilter-data';

export abstract class CachedListDataResolve implements Resolve<any> {

  abstract action: ((params: GridFilterRequestPropertiesViewModel) => Action);

  cachedState: ParamData = {
    params: null,
    timeStamp: null,
  };

  protected constructor(
    public gridFilterDataService: GridfilterDataService<any>,
    public store$: Store<{}>,
  ) {
    this.subscribeToStore();
  }

  abstract subscribeToStore();

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    if (this.cachedState != null && this.cachedState.params !== null && this.isStillValid(this.cachedState.timeStamp)) {
      return Promise.resolve(this.cachedState.params);
    } else {
      return this.gridFilterDataService.getRequestProperties().toPromise()
        .then(params => {
          this.store$.dispatch(this.action(params));
          return params;
        });
    }
  }

  isStillValid(timeStamp: Date): boolean {
    // reload every hour
    return timeStamp.getTime() >= new Date().setHours(new Date().getHours() - 1);
  }
}
