import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoadingBarService } from './loading-bar.service';

@Injectable()
export class LoadingBarInterceptorService implements HttpInterceptor {

  private static inProgressCount = 0;

  constructor(public loadingBarService: LoadingBarService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    LoadingBarInterceptorService.inProgressCount++;
    this.loadingBarService.start();
    this.loadingBarService.progress /= 2;
    return next.handle(req).pipe(finalize(async () => {
      LoadingBarInterceptorService.inProgressCount--;
      if (LoadingBarInterceptorService.inProgressCount === 0) {
        this.loadingBarService.complete();
      }
    }));
  }
}
