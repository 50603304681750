import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoggingService } from '@shared/services/logging/logging.service';
import { Action, Store } from '@ngrx/store';
import * as userActions from './user.actions';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AccountService } from '@shared/services/account/account.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SessionPath } from '@shared/models/session-path.model';
import {
  ChangePasswordViewModel,
  ResetAccountViewModel,
  SetNewPasswordViewModel,
  UserViewModel
} from '@circlon/operate-api-model';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';
import { RootStoreState } from '@app/root-store';
import { RoleDataService } from '@operate/role/data-access';
import { Observable, of } from 'rxjs';
import { ThemeService } from '@shared/services/theme/theme.service';

@Injectable({
  providedIn: 'root'
})
export class UserStoreEffects {

  constructor(
    private actions$: Actions,
    private logger: LoggingService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private monitoringService: MonitoringService,
    private store$: Store<RootStoreState.State>,
    private roleDataService: RoleDataService,
    private themeService: ThemeService,
  ) {
  }

  @Effect()
  userLoadEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserLoadAction>(
      userActions.ActionTypes.USER_LOAD
    ),
    switchMap(() => {
      return this.accountService.getCurrentUser$()
        .pipe(
          map(user =>
            new userActions.UserSuccessAction({ user: user })
          ),
          catchError(error =>
            of(new userActions.UserFailureAction({ error }))
          )
        );
    })
  );

  @Effect()
  userSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserSuccessAction>(userActions.ActionTypes.USER_SUCCESS),
    switchMap(action => {
      const user = action.payload.user;
      this.localStorageService.setItem(SessionPath.USER, user);
      this.logger.debug('SessionService - setCurrentUser:\n', `new user\n`, user);

      if (user.tenantTheme) {
        this.themeService.applyTheme(user.tenantTheme);
      }

      return this.roleDataService.roleDetails$(user.roleId)
        .pipe(
          map(role => this.monitoringService.setAuthenticatedUserId(user.id, role.name)),
          map(() => new userActions.UserSavedAction())
        );
    }),
  );

  @Effect()
  userUpdateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserUpdateAction>(userActions.ActionTypes.USER_UPDATE),
    map(action => {
      this.localStorageService.setItem(SessionPath.USER, action.payload.user);
      this.logger.debug('SessionService - setCurrentUser:\n', `new user\n`, action.payload.user);

      return new userActions.UserSavedAction();
    })
  );

  @Effect()
  userProfileUpdateEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserProfileUpdateAction>(userActions.ActionTypes.USER_PROFILE_UPDATE),
    withLatestFrom(this.store$.select(state => state.user.user)),
    map(([action, user]) => {
      const newUser = UserViewModel.fromJS({...action.payload.user, ...user});
      this.localStorageService.setItem(SessionPath.USER, newUser);
      this.logger.debug('SessionService - setCurrentUser:\n', `new user\n`, action.payload.user);

      return new userActions.UserProfileUpdatedAction();
    })
  );

  @Effect()
  userResetPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserResetPasswordAction>(userActions.ActionTypes.USER_RESET_PASSWORD),
    switchMap(action => {
      return this.accountService.resetAccount$(new ResetAccountViewModel({ email: action.payload.email })).pipe(
        map(() => {
          return new userActions.UserResetPasswordSuccessAction();
        }),
        catchError(error => {
          return of(new userActions.UserResetPasswordFailureAction({ error }));
        })
      );
    })
  );

  @Effect()
  userSetNewPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserSetNewPasswordAction>(userActions.ActionTypes.USER_SET_NEW_PASSWORD),
    switchMap(action => {
      return this.accountService.setNewPassword$(new SetNewPasswordViewModel({ newPassword: action.payload.password })).pipe(
        map(() => new userActions.UserSetNewPasswordSuccessAction({ password: action.payload.password })),
        catchError(error => of(new userActions.UserSetNewPasswordFailureAction({ error })))
      );
    })
  );

  @Effect()
  userSetNewPasswordFromExpiredEffect$: Observable<Action> = this.actions$.pipe(
    ofType<userActions.UserSetNewPasswordFromExpiredAction>(userActions.ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED),
    switchMap(action => {
      return this.accountService.changePassword$(new ChangePasswordViewModel(
        {
          password: action.payload.password,
          newPassword: action.payload.newPassword,
          passwordConfirmation: action.payload.passwordConfirmation
        })).pipe(
        map(() => new userActions.UserSetNewPasswordFromExpiredSuccessAction({ password: action.payload.newPassword })),
        catchError(error => of(new userActions.UserSetNewPasswordFromExpiredFailureAction({ error })))
      );
    })
  );

  @Effect()
  userLogoutEffect$ = this.actions$.pipe(
    ofType<userActions.UserLogoutAction>(userActions.ActionTypes.USER_LOGOUT),
    switchMap(() => {
      this.themeService.clearTheme();
      this.localStorageService.setItem(SessionPath.USER, null);
      return of(new userActions.UserStorageWriteSuccess());
    })
  );

}
