import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { LoggingService } from '@shared/services/logging/logging.service';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import * as authActions from './auth.actions';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { LoginResponse } from '@shared/models';
import { UserStoreActions } from '@root-store/user-store';
import { ImpersonationStoreActions } from '@root-store/impersonation-store';
import { OauthInfo } from '@shared/models/oauth-info.model';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { SessionState } from '@shared/services/session/session-state';
import { RootStoreState } from '@app/root-store';
import { Router } from '@angular/router';
import { SessionPath } from '@shared/models/session-path.model';
import { OauthService } from '@shared/services/oauth/oauth.service';
import { StartPageService } from '@shared/services/start-page/start-page.service';
import { SessionStateService } from '@shared/services/session/session-state.service';
import { QueryEncoder } from '@shared/models/query-encoder';
import { EMPTY, of } from 'rxjs';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStoreEffects {

  constructor(
    private actions$: Actions,
    private logger: LoggingService,
    private localStorageService: LocalStorageService,
    private oauthService: OauthService,
    private store$: Store<RootStoreState.State>,
    private router: Router,
    private startPageService: StartPageService,
    private sessionStateService: SessionStateService,
    private monitoringService: MonitoringService,
  ) {
  }

  @Effect()
  authLoginEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthLoginAction>(
      authActions.ActionTypes.AUTH_LOGIN
    ),
    switchMap(action => {
      this.logger.debug('AuthStore:', `login with user ${action.payload.user} pass ${action.payload.password}`);

      const body: string = new HttpParams({ encoder: new QueryEncoder()})
        .set('grant_type', 'password')
        .set('password', action.payload.password)
        .set('username', action.payload.user)
        .toString();

      return this.oauthService.getToken$(body)
        .pipe(
          map(loginResponse => {
            const oAuthInfo = this.getAuth(loginResponse);

            const sessionState = this.sessionStateService.determineSessionState(oAuthInfo);

            if (oAuthInfo.expires <= Date.now()) {
              return new authActions.AuthFailureAction({ error: 'Login data expired' });
            }

            return new authActions.AuthSuccessAction({ auth: oAuthInfo, sessionState });
          }),
          catchError(error =>
            of(new authActions.AuthFailureAction({ error }))
          )
        );
    })
  );

  @Effect()
  authLoginFromStorageEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthLoginFromStorageAction>(authActions.ActionTypes.AUTH_LOGIN_FROM_STORAGE),
    map(() => {
      const auth = this.localStorageService.getItem(SessionPath.OAUTH);
      const user = this.localStorageService.getItem(SessionPath.USER);
      const targets = this.localStorageService.getItem(SessionPath.TARGETS);

      if (auth && user) {
        // Session expired, do not init
        if ((auth as OauthInfo).expires < Date.now()) {
          this.localStorageService.setItem('session', null);
          return new authActions.AuthFailureAction({ error: 'Login data expired' });
        }

        let target = null;
        if (user.id !== null) {
          target = this.localStorageService.getUserItem(user.id, SessionPath.CONTEXT);
        }

        const sessionState = this.sessionStateService.determineSessionState(auth);

        // do not just continue to password set screens from storage, log user out and let him log in again
        if (sessionState === SessionState.NeedsPasswordChange || sessionState === SessionState.PasswordExpired) {
          return new authActions.AuthFailureAction({ error: 'Login data expired' });
        }

        return new authActions.AuthLoginSuccessFromStorageAction({ auth, sessionState, user, targets, target });
      }
      return new authActions.AuthFailureAction({ error: 'Login data expired' });
    })
  );

  @Effect()
  authLoginSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthSuccessAction>(
      authActions.ActionTypes.AUTH_SUCCESS
    ),
    map(action => action.payload),
    concatMap(() => [
      new UserStoreActions.UserLoadAction(),
      new ImpersonationStoreActions.LoadImpersonationTargets(),
    ]),
  );

  @Effect()
  authLogoutEffect$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.ActionTypes.AUTH_LOGOUT),
    switchMap(() => {
      this.localStorageService.setItem(SessionPath.OAUTH, null);
      this.monitoringService.clearUserId();

      return this.router.navigate(['/auth/login'])
        .then(() => of(null))
        .catch(() => of(null));
    }),
    switchMap(() => {
      return of(new authActions.AuthLogoutSuccessAction());
    })
  );

  @Effect()
  authLogoutSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthLogoutSuccessAction>(authActions.ActionTypes.AUTH_LOGOUT_SUCCESS),
    concatMap(() => [
      new UserStoreActions.UserLogoutAction(),
      new ImpersonationStoreActions.ImpersonationLogout(),
    ])
  );

  @Effect()
  authRefreshSingleEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthRefreshSingleAction>(authActions.ActionTypes.AUTH_REFRESH_SINGLE),
    withLatestFrom(this.store$.select(state => state.auth)),
    switchMap(([action, auth]) => {
      if (auth.refreshTokenLoading) {
        return EMPTY;
      } else {
        return of(new authActions.AuthRefreshAction());
      }
    })
  );

  @Effect()
  authRefreshEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthRefreshSuccessAction>(authActions.ActionTypes.AUTH_REFRESH),
    withLatestFrom(this.store$.select(state => state.auth.oauth)),
    switchMap(([action, auth]) => {
      this.logger.debug('AuthService:', 'refresh Token');

      const body: string = new HttpParams()
        .set('grant_type', 'refresh_token')
        .set('refresh_token', auth.refresh_token)
        .toString();

      return this.oauthService.getToken$(body)
        .pipe(
          map(loginResponse => {
            const oAuthInfo = this.getAuth(loginResponse);
            if ((oAuthInfo as OauthInfo).expires < Date.now()) {
              return new authActions.AuthExpiredShowLoginAction();
            }
            return new authActions.AuthRefreshSuccessAction({ auth: oAuthInfo });
          }),
          catchError(() => of(new authActions.AuthExpiredShowLoginAction()))
        );
    })
  );

  @Effect()
  authRefreshFailureEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthRefreshFailureAction>(authActions.ActionTypes.AUTH_REFRESH_FAILURE),
    map(() => new authActions.AuthLogoutAction())
  );

  @Effect()
  authExpiredLoginEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthExpiredLoginAction>(
      authActions.ActionTypes.AUTH_EXPIRED_LOGIN
    ),
    withLatestFrom(this.store$.select(state => state)),
    switchMap(([action, state]) => {
      this.logger.debug('AuthStore:', `expired login with user ${state.user.user.userName} pass ${action.payload.password}`);

      const body: string = new HttpParams({ encoder: new QueryEncoder()})
        .set('grant_type', 'password')
        .set('password', action.payload.password)
        .set('username', state.user.user.userName)
        .toString();

      return this.oauthService.getToken$(body)
        .pipe(
          map(loginResponse => {
            const oAuthInfo = this.getAuth(loginResponse);

            const sessionState = this.sessionStateService.determineSessionState(oAuthInfo);

            if (oAuthInfo.expires <= Date.now()) {
              return new authActions.AuthExpiredLoginFailureAction({ error: 'Login data expired' });
            }

            if (sessionState !== SessionState.LoggedIn) {
              return new authActions.AuthExpiredLoginRerouteToAuthAction({ auth: oAuthInfo, sessionState });
            }

            return new authActions.AuthExpiredLoginSuccessAction({ auth: oAuthInfo, sessionState });
          }),
          catchError(error =>
            of(new authActions.AuthExpiredLoginFailureAction({ error }))
          )
        );
    })
  );

  @Effect()
  authExpiredLoginRedirectNeededEffect$ = this.actions$.pipe(
    ofType<authActions.AuthExpiredLoginRerouteToAuthAction>(authActions.ActionTypes.AUTH_EXPIRED_LOGIN_REROUTE_NEEDED),
    switchMap(action => {
      if (action.payload.sessionState === SessionState.NeedsPasswordChange) {
        this.router.navigate(['/auth', 'setPassword']);
      } else if (action.payload.sessionState === SessionState.PasswordExpired) {
        this.router.navigate(['/auth', 'expiredPassword']);
      } else {
        return of(new authActions.AuthLogoutAction());
      }
      return EMPTY;
    })
  );

  @Effect()
  authLoginFromStorageSuccessEffect$: Observable<Action> = this.actions$.pipe(
    ofType<authActions.AuthLoginSuccessFromStorageAction>(
      authActions.ActionTypes.AUTH_LOGIN_SUCCESS_FROM_STORAGE
    ),
    concatMap(action => [
      action.payload.user.hasOwnProperty('roleId')
        ? new UserStoreActions.UserSuccessAction({ user: action.payload.user })
        : new UserStoreActions.UserLoadAction(),
      new ImpersonationStoreActions.LoadImpersonationTargetsSuccess(action.payload.targets),
      new ImpersonationStoreActions.LoadImpersonationTargetSuccess(action.payload.target),
    ])
  );

  @Effect()
  authAllLoginDataLoadedEffect$ = this.actions$.pipe(
    ofType(
      UserStoreActions.ActionTypes.USER_SUCCESS,
      ImpersonationStoreActions.ActionTypes.LOAD_IMPERSONATION_TARGETS_SUCCESS,
    ),
    withLatestFrom(this.store$.select(state => state)),
    filter(([action, state]) =>
      [SessionState.NeedsPasswordChange, SessionState.PasswordExpired, SessionState.LoggedIn].includes(state.auth.sessionState)
      && !state.auth.loginProcessDone
      && state.impersonation.loaded
      && state.user.loaded
    ),
    concatMap((): Observable<Action> => {
      return of(new authActions.AuthRedirectAction());
    })
  );

  @Effect()
  authFailWhileLoginDataLoadingEffect$ = this.actions$.pipe(
    ofType(
      UserStoreActions.ActionTypes.USER_FAILURE,
      ImpersonationStoreActions.ActionTypes.LOAD_IMPERSONATION_TARGETS_FAIL,
    ),
    withLatestFrom(this.store$.select(state => state)),
    concatMap(([action, state]): Observable<Action> => {
      if ((state.auth.sessionState === SessionState.LoggedIn || state.auth.sessionState === SessionState.NeedsPasswordChange)
        && !state.auth.loginProcessDone) {
        return of(new authActions.AuthLogoutAction());
      } else {
        return of(new authActions.AuthRedirectNotReadyAction());
      }
    })
  );

  @Effect()
  authLoginRedirect$ = this.actions$.pipe(
    ofType<authActions.AuthRedirectAction>(authActions.ActionTypes.AUTH_REDIRECT),
    withLatestFrom(
      this.store$.select(state => state.auth),
      this.store$.select(state => state.user),
    ),
    concatMap(([action, authState, userState]) => {
      if (authState.sessionState === SessionState.NeedsPasswordChange) {
        this.router.navigate(['/auth', 'setPassword']);
      } else if (authState.sessionState === SessionState.PasswordExpired) {
        this.router.navigate(['/auth', 'expiredPassword']);
      } else {
        this.router.navigate(this.startPageService.getFirstRoute(userState.user.operations));
        return of(new ImpersonationStoreActions.SetImpersonationAfterLogin());
      }
      return EMPTY;
    })
  );

  @Effect()
  authNewPasswordEffect$: Observable<Action> = this.actions$.pipe(
    ofType<UserStoreActions.UserSetNewPasswordSuccessAction>(UserStoreActions.ActionTypes.USER_SET_NEW_PASSWORD_SUCCESS),
    withLatestFrom(this.store$.select(state => state)),
    concatMap(([action, state]) => {
      return of(new authActions.AuthLoginAction({ user: state.user.user.userName, password: action.payload.password }));
    })
  );

  @Effect()
  authExpiredPassword$ = this.actions$.pipe(
    ofType<UserStoreActions.UserSetNewPasswordFromExpiredSuccessAction>(
      UserStoreActions.ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED_SUCCESS
    ),
    withLatestFrom(this.store$.select(state => state)),
    concatMap(([action, state]) => {
      return of(new authActions.AuthLoginAction({ user: state.user.user.userName, password: action.payload.password }));
    })
  );

  private getAuth(loginResponse: LoginResponse): OauthInfo {
    const oAuthPath = 'session.oAuth';

    // Build OauthInfo obj.
    const oAuthInfo: OauthInfo = loginResponse as OauthInfo;
    oAuthInfo.expires = loginResponse['expires'] || Date.now() + (loginResponse.expires_in * 1000);
    oAuthInfo.expires_in = Math.floor((oAuthInfo.expires - Date.now()) / 1000);

    this.localStorageService.setItem(oAuthPath, oAuthInfo);
    this.logger.debug('Auth Effect - OAuth:\n', `new token expires at ${new Date(oAuthInfo.expires).toString()}\n`, oAuthInfo);
    return oAuthInfo;
  }

}
