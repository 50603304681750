import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'opr-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss']
})
export class LoadingOverlayComponent {

  @HostBinding('class.visible') get valid() { return this._visible; }

  @Input() get visible() {
    return this._visible;
  }

  set visible(isVisible: boolean) {
    this._visible = isVisible;
    this.visibleChange.emit(isVisible);
    this.showSuccess = false;
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
  }

  @Input() set successAndHide(successAndHide: boolean) {
    this.showSuccess = successAndHide;

    if (this.showSuccess) {
      this.hideTimer = setTimeout(() => {
        this._visible = false;
        this.visibleChange.emit(false);
      }, 800);
    } else {
      if (this.hideTimer) {
        clearTimeout(this.hideTimer);
      }
    }
  }

  @Input() text = '';
  @Input() textParams = null;
  @Input() cancelable = false;
  @Input() spinnerSize = 30;

  @Output() cancel = new EventEmitter();
  @Output() visibleChange = new EventEmitter<boolean>();

  showSuccess = false;

  private _visible = false;
  private hideTimer;

  emitCancel() {
    this.cancel.emit();
  }
}
