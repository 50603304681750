import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { GridfilterDataService } from '../gridfilter-data';

export abstract class ListDataResolve implements Resolve<any> {
  protected constructor(public gridFilterDataService: GridfilterDataService<any>) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
      return this.gridFilterDataService.getRequestProperties()
        .toPromise();
  }

  isStillValid(timeStamp: Date): boolean {
    // reload every hour
    return timeStamp.getTime() >= new Date().setHours(new Date().getHours() - 1);
  }
}
