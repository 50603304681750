import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { SessionState } from '@shared/services/session/session-state';
import { UserViewModel } from '@circlon/operate-api-model';
import { LoggingService } from '@shared/services/logging/logging.service';
import { PermissionService } from '@shared/services/permission/permission.service';
import { select, Store } from '@ngrx/store';
import { AuthStoreSelectors, RootStoreState, UserStoreSelectors } from '@app/root-store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  private sessionState: SessionState;
  private user: UserViewModel = null;

  constructor(
    private logger: LoggingService,
    private router: Router,
    private permissionService: PermissionService,
    private store$: Store<RootStoreState.State>,
  ) {
    this.store$.pipe(select(AuthStoreSelectors.selectSessionState))
      .subscribe(sessionState => {
        this.sessionState = sessionState;
      });

    this.store$.pipe(select(UserStoreSelectors.selectUser))
      .subscribe(user => {
        this.user = user;
      });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.logger.debug('AuthGuard canActivate() called');
    if (this.sessionState === SessionState.NeedsPasswordChange) {
      this.logger.debug('AuthGuard: Access denied. Password needs change - Redirect');
      this.router.navigate(['/auth/setPassword']);
      return false;
    }
    if (this.sessionState === SessionState.PasswordExpired) {
      this.logger.debug('AuthGuard: Access denied. Password expired - Redirect');
      this.router.navigate(['/auth/expiredPassword']);
      return false;
    }
    if (this.sessionState !== SessionState.LoggedIn) {
      this.logger.debug('AuthGuard: Access denied. needs login - Redirect');
      // could store old route here to redirect after login success
      this.router.navigate(['/auth/login']);
      return false;
    }
    if (this.permissionNeedsToBeChecked(route)) {
      const permission = this.permissionService.canAccess(route.data.oprNavEntry.permission);
      if (!permission) {
        this.logger.debug('AuthGuard: Access denied. needs permission - Redirect');
        this.router.navigate(['/error/permission'], {skipLocationChange: true});
        return false;
      }
    }
    return true;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.canActivate(childRoute, state);
  }

  private permissionNeedsToBeChecked(route: ActivatedRouteSnapshot) {
    return route.data && route.data.oprNavEntry && route.data.oprNavEntry.permission;
  }
}
