import { GridFilterComparisonEnumeration } from '@circlon/operate-api-model';
import { FilterField } from './filter-field.model';

export class ActiveFilter {
  constructor(private field: FilterField) {
  }

  get params() {
    if (this.model.length === 1) {
      return {
        [this.comparison]: {
          [this.property]: this.model[0],
        }
      };
    } else if (this.model.length > 1) {
      return {
        [this.comparisonType]: this.model.map(model => {
          return {
            [this.comparison]: {
              [this.property]: model,
            }
          };
        })
      };
    } else {
      return {};
    }
  }

  get comparison(): string {
    return GridFilterComparisonEnumeration[this.field.activeComparison].toLowerCase();
  }

  get property(): string {
    return this.field.property;
  }

  private get model(): any[] {
    return this.field.model || [];
  }

  private get comparisonType(): string {
    switch (this.field.activeComparison) {
      case GridFilterComparisonEnumeration.NotContains:
      case GridFilterComparisonEnumeration.NotEquals:
        return 'and';
      default:
        return 'or';
    }
  }
}
