import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PermissionKeyService {

  // Asset
  static assetView = 'Asset.AssetView';
  static assetLocationAssignment = 'Asset.LocationAssignment';
  static assetBundling = 'Asset.AssetBundling';
  static assetManagement = 'Asset.AssetManagement';
  static assetDeactivation = 'Asset.AssetDeactivation';
  static assetStatusChange = 'Asset.StatusChange';
  static assetStatusReset = 'Asset.StatusReset';

  // Batch
  static batchImport = 'Batch.BatchImport';

  // Asset Definition
  static assetTypeView = 'Device.TypeView';
  static assetTypeManagement = 'Device.TypeManagement';
  static tenantAssetDefinitionView = 'Device.ModelClientView';
  static tenantAssetDefinitionManagement = 'Device.ModelClientManagement';
  static assetDefinitionView = 'Device.ModelView';
  static assetDefinitionManagement = 'Device.ModelManagement';

  // Model
  static serviceContractView = 'Device.ServiceContractView';
  static serviceContractManagement = 'Device.ServiceContractManagement';

  // Report
  static reportView = 'Report.ReportView';

  // Service
  static rmaView = 'Service.RmaView';
  static rmaVerifyRepair = 'Service.RmaVerifyRepair';
  static rmaExchange = 'Service.RmaExchange';
  static rmaSendExchange = 'Service.RmaSendExchange';
  static rmaRepair = 'Service.RmaRepair';
  static rmaSendRepair = 'Service.RmaSendRepair';
  static rmaClose = 'Service.RmaClose';
  static rmaReceiveDevice = 'Service.RmaReceiveDevice';
  static rmaChangeAsset = 'Service.RmaChangeAsset';
  static rmaInitialCheck = 'Service.RmaInitialCheck';
  static rmaGenerate = 'Service.RmaGenerate';
  static rollInView = 'Rollin.RollinView';
  static rollInManagement = 'Rollin.RollinManagement';
  static rollInClose = 'Rollin.RollinClose';
  static rollInReceive = 'Rollin.RollinReceive';

  // Tenant
  static tenantView = 'Tenant.TenantView';
  static tenantManagement = 'Tenant.TenantManagement';
  static serviceProviderView = 'Tenant.ServiceProviderView';
  static serviceProviderManagement = 'Tenant.ServiceProviderManagement';
  static exchangePoolView = 'Tenant.ExchangePoolView';
  static exchangePoolManagement = 'Tenant.ExchangePoolManagement';
  static manufacturerView = 'Tenant.ManufacturerView';
  static manufacturerManagement = 'Tenant.ManufacturerManagement';

  // User
  static userView = 'User.UserView';
  static userManagement = 'User.UserManagement';
  static roleView = 'User.RoleView';
  static roleManagement = 'User.RoleManagement';

  // Language
  static languageView = 'Language.LanguageView';
  static languageManagement = 'Language.LanguageManagement';

  // --- Compound keys ---

  // Aside menu
  static asideViewAsset: string[] = [
    PermissionKeyService.assetLocationAssignment,
    PermissionKeyService.serviceContractManagement,
    PermissionKeyService.rollInManagement,
    PermissionKeyService.rollInReceive,
  ];
  static asideViewRma: string[] = [PermissionKeyService.rmaVerifyRepair];
  static asideViewUser: string[] = [PermissionKeyService.userManagement, PermissionKeyService.roleView];

}
