import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { LoggingService } from '../logging/logging.service';
import { I18nDataService } from '../i18n-data/i18n-data.service';
import { TranslationService } from './translation.service';

@Injectable()
export class TranslationResolver implements Resolve<void> {
  private static loadedTranslations = new Map<string, boolean>();

  constructor(private translateService: TranslateService,
              private translationService: TranslationService,
              private httpClient: HttpClient,
              private logger: LoggingService,
              private i18nDataService: I18nDataService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const lang = this.translationService.currentLanguage;
    const groups: string[] = typeof route.data.translation === 'string' ? [route.data.translation] : route.data.translation;
    const resolves = groups.map(group => this.fetchTranslations(lang, group));
    return Promise.all(resolves).then(() => Promise.resolve());
  }

  private fetchTranslations(lang: string, group: string): Promise<void> {
    if (!TranslationResolver.loadedTranslations.get(`${lang}.${group}`)) {
      TranslationResolver.loadedTranslations.set(`${lang}.${group}`, true);
      return this.i18nDataService.getTranslations(lang, group).then(next => {
        this.translateService.setTranslation(lang, next, true);
      });
    }
    return Promise.resolve();
  }
}
