import { HttpErrorResponse } from '@angular/common/http';
import { Alert } from '../alert/alert.model';

export class HttpErrorAlert extends HttpErrorResponse {
  constructor(err: HttpErrorResponse, readonly alert: Alert) {
    super({
      error: err.error,
      headers: err.headers,
      status: err.status,
      statusText: err.statusText,
      url: err.url,
    });
  }
}
