import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from '../../services/alert/alert.service';
import { Subject } from 'rxjs';
import { Alert, AlertType } from '../../services/alert/alert.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'opr-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit, OnDestroy {
  alerts = new Set<Alert>();

  private ngUnsubscribe = new Subject();

  constructor(private alertService: AlertService) {
  }

  ngOnInit() {
    this.alertService.alerts$
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((alerts: Set<Alert>) => {
        this.alerts = alerts;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    // return css class based on alert type
    switch (alert.type) {
      case AlertType.Success:
        return 'alert alert-success';
      case AlertType.Error:
        return 'alert alert-danger';
      case AlertType.Info:
        return 'alert alert-info';
      case AlertType.Warning:
        return 'alert alert-warning';
    }
  }
}
