import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { featureReducer } from './user.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserStoreEffects } from './user.effects';
import { RoleDataAccessModule } from '@operate/role/data-access';

@NgModule({
  declarations: [],
  imports: [
    RoleDataAccessModule,
    StoreModule.forFeature('user', featureReducer),
    EffectsModule.forFeature([UserStoreEffects])
  ]
})
export class UserStoreModule { }
