import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { RoleEditViewModel, RoleViewModel } from '@circlon/operate-api-model';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleDataService {
  private baseUrl = `${this.appConfig.apiBaseEndpoint}v1.0/roles`;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
  }

  roleDetails(roleId: number): Promise<RoleEditViewModel> {
    const url = `${this.baseUrl}/${roleId}`;
    return this.httpClient.get<RoleEditViewModel>(url)
      .toPromise()
      .then(res => RoleEditViewModel.fromJS(res));
  }

  roleDetails$(roleId: number): Observable<RoleEditViewModel> {
    const url = `${this.baseUrl}/${roleId}`;
    return this.httpClient.get<RoleEditViewModel>(url)
      .pipe(map(res => RoleEditViewModel.fromJS(res)));
  }

  saveRole(role: RoleEditViewModel): Promise<RoleEditViewModel> {
    const url = `${this.baseUrl}`;
    return this.httpClient.post<RoleEditViewModel>(url, role)
      .toPromise()
      .then(res => RoleEditViewModel.fromJS(res));
  }

  roleList(locations: number[]): Promise<RoleViewModel[]> {
    const url = `${this.baseUrl}/list`;
    let params = new HttpParams();
    params = params.append('locations', locations.join(', '));

    return this.httpClient.get<RoleViewModel[]>(url, {params: params})
      .toPromise()
      .then(res => res.map(el => RoleViewModel.fromJS(el)));
  }

  delete(roleId: number): Promise<void> {
    const url = `${this.baseUrl}/${roleId}`;
    return this.httpClient.delete<void>(url)
      .toPromise();
  }

}
