import { InjectionToken } from '@angular/core';

export interface AppConfig {
  branding: string;
  brandName: string;
  name: string;
  version: string;
  apiBaseEndpoint: string;
  aiKey: string;
  map: {
    apiKey: string;
    tileUrl: string;
  };
  reports?: {
    tokenValidDuration?: number;
  };
}

export const APP_CONFIG = new InjectionToken<AppConfig>('Runtime configuration');
