import {
  GridFilterMethodEnumeration,
  GridFilterParameters,
  GridFilterRequestPropertiesViewModel,
  IGridFilterParameters,
  IGridFilterRequest
} from '@circlon/operate-api-model';
import { GridfilterRequestService } from './gridfilter-request.service';
import { GenericGridFilterResponse } from './generic-grid-filter-response';
import { Observable } from 'rxjs';

export abstract class GridfilterDataService<T> {

  protected constructor(
    private requestService: GridfilterRequestService,
    private method: GridFilterMethodEnumeration,
  ) {}

  getRequestProperties(): Observable<GridFilterRequestPropertiesViewModel> {
    return this.requestService.getRequestProperties(this.method);
  }

  requestList(params: IGridFilterParameters, instant: boolean = false): Observable<GenericGridFilterResponse<T>> {
    return this.requestService.requestList(this.generateRequest(params), instant);
  }

  requestExport(params: IGridFilterParameters, fileName: string): Promise<boolean> {
    return this.requestService.requestExport(this.generateRequest(params), fileName);
  }

  private generateRequest(params: IGridFilterParameters): IGridFilterRequest {
    return {
      method: GridFilterMethodEnumeration[this.method],
      params: GridFilterParameters.fromJS(params),
    };
  }
}
