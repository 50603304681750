import { featureAdapter, State } from './impersonation.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { ContextTargetData } from '@circlon/operate-api-model';

export const getImpersonationLoading = (state: State): boolean => state.loading;
export const getImpersonationLoaded = (state: State): boolean => state.loaded;
export const getImpersonation = (state: State): ContextTargetData => state.impersonated;

export const selectImpersonationState: MemoizedSelector<object, State>
  = createFeatureSelector<State>('impersonation');

export const selectImpersonationTargets: (
  state: object
) => ContextTargetData[] = featureAdapter.getSelectors(selectImpersonationState).selectAll;

export const selectImpersonationTargetsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectImpersonationState, getImpersonationLoading
);

export const selectImpersonationTargetsLoaded: MemoizedSelector<object, boolean> = createSelector(
  selectImpersonationState, getImpersonationLoaded
);

export const selectImpersonationTarget: MemoizedSelector<object, ContextTargetData> = createSelector(
  selectImpersonationState, getImpersonation
);
