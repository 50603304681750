import { environment as defaultEnvironment } from './environment';

export const environmentLoader = new Promise<any>((resolve, reject) => {
  const xmlhttp = new XMLHttpRequest();
  const method = 'GET';
  const url = './assets/config.json';
  xmlhttp.open(method, url, true);
  xmlhttp.onload = function() {
    if (xmlhttp.status === 200) {
      resolve(xmlhttp.response);
    } else {
      resolve(defaultEnvironment);
    }
  };
  xmlhttp.send();
});
