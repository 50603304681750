import { Injectable, InjectionToken } from '@angular/core';
import { Logging } from './logging.model';
import { LoggingLevel } from './logging-level.enum';

export const LOG_LEVEL = new InjectionToken<LoggingLevel>('Logging service log level');

@Injectable()
export class LoggingService implements Logging {
  debug: (...msg: any[]) => void;
  info: (...msg: any[]) => void;
  warn: (...msg: any[]) => void;
  error: (...msg: any[]) => void;
}
