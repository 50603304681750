import { LoginResponse } from '@shared/models';
import { SessionState } from '@shared/services/session/session-state';

export interface State {
  oauth: LoginResponse;
  sessionState: SessionState;
  loading: boolean;
  refreshTokenLoading: boolean;
  refreshLoginInProcess: boolean;
  initializing: boolean;
  loginProcessDone: boolean;
  error: any;
}

export const initialState: State = {
  oauth: null,
  sessionState: SessionState.LoggedOut,
  loading: false,
  refreshTokenLoading: false,
  refreshLoginInProcess: false,
  initializing: false,
  loginProcessDone: false,
  error: null,
};
