import { Injectable } from '@angular/core';
import { OauthInfo } from '@shared/models/oauth-info.model';
import { SessionState } from '@shared/services/session/session-state';

@Injectable({
  providedIn: 'root'
})
export class SessionStateService {

  determineSessionState(info: OauthInfo): SessionState {
    if (!info) {
      return SessionState.LoggedOut;
    }
    if (info.expires <= Date.now()) {
      return SessionState.Expired;
    }
    if (info.require_password_change) {
      return SessionState.NeedsPasswordChange;
    }
    if (info.password_expired) {
      return SessionState.PasswordExpired;
    }
    return SessionState.LoggedIn;
  }

}
