import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from '@shared/models';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class OauthService {

  private authApiUrl = `${this.appConfig.apiBaseEndpoint}oauth/token`;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig
  ) { }

  getToken$(body: string): Observable<LoginResponse> {
    return this.httpClient
      .post<LoginResponse>(
        this.authApiUrl,
        body,
        { headers: this.header });
  }

  private get header(): HttpHeaders {
    return new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', 'Basic Q2lyY2xvbjpwZHM=');
  }
}
