import { Injectable } from '@angular/core';
import { GridfilterDataService, GridfilterRequestService } from '@operate/grid-filter/data';
import { GridFilterI18nViewModel, GridFilterMethodEnumeration } from '@circlon/operate-api-model';

@Injectable()
export class I18nListService extends GridfilterDataService<GridFilterI18nViewModel> {

  constructor(requestService: GridfilterRequestService) {
    super(requestService, GridFilterMethodEnumeration.GridFilterI18nCommand);
  }
}
