import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefDeserialize } from './ref-deserialize';
import { tap } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable()
export class RefDeserializeInterceptor implements HttpInterceptor {

  constructor(@Inject(APP_CONFIG) private appConfig: AppConfig) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse && req.url.startsWith(this.appConfig.apiBaseEndpoint)) {
        RefDeserialize.Retrocycle(event.body);
      }
    }));
  }
}
