import { Injectable } from '@angular/core';
import { snakeCase } from 'lodash-es';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationKeyService {

  constructor(private translateService: TranslateService) {
  }

  generateTranslationKey(namespace: string, ...args: string[]): string {
    return [namespace, ...args].map(part => snakeCase(part).toUpperCase()).join('.');
  }

  translateInstant(namespace: string, ...args: string[]): string {
    return this.translateInstantParam(null, namespace, ...args);
  }

  translateInstantParam(params: object, namespace: string, ...args: string[]): string {
    return this.translateService.instant(this.generateTranslationKey(namespace, ...args), params);
  }

}
