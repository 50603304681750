import { RouterFilter, State } from './router.state';
import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';

export const getRoute = (state: State): string[] => state.currentRoute;
const getTranslationGroups = (state: State): string[] => state.translationGroups;
const getFilter = (state: State): RouterFilter => state.filter;

export const selectAssetState: MemoizedSelector<object, State> = createFeatureSelector<State>('router');

export const selectRoute: MemoizedSelector<Object, string[]> = createSelector(selectAssetState, getRoute);
export const selectTranslationGroups: MemoizedSelector<Object, string[]> = createSelector(selectAssetState, getTranslationGroups);
export const selectFilter: MemoizedSelector<Object, RouterFilter> = createSelector(selectAssetState, getFilter);
