import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { TranslationResolver } from '@shared/services/translation/translation.resolve';
import { AuthGuard } from '@shared/services/auth-guard/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
    data: {
      translation: 'COMMON'
    },
    resolve: {
      lang: TranslationResolver
    }
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    loadChildren: () => import('./operate/operate.module').then(m => m.OperateModule),
  }
];

const routingConfiguration: ExtraOptions = {
    paramsInheritanceStrategy: 'always',
    relativeLinkResolution: 'legacy'
};

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      routingConfiguration,
    ),
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    AuthGuard,
  ]
})
export class AppRoutingModule {
}
