import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class CollapseService {
  private detailCardCollapsedSubject = new BehaviorSubject<boolean>(true);
  private gridFilterCardCollapsedSubject = new BehaviorSubject<boolean>(true);

  get detailCardCollapsed$(): Observable<boolean> {
    return this.detailCardCollapsedSubject.asObservable();
  }

  set detailCardCollapsed(isCollapsed: boolean) {
    this.detailCardCollapsedSubject.next(isCollapsed);
  }

  get gridFilterCardCollapsed$(): Observable<boolean> {
    return this.gridFilterCardCollapsedSubject.asObservable();
  }

  set gridFilterCardCollapsed(isCollapsed: boolean) {
    this.gridFilterCardCollapsedSubject.next(isCollapsed);
  }
}
