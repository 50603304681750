import { Injectable } from '@angular/core';
import { LoggingService } from '../logging/logging.service';
import { GridFilterPaging } from '@circlon/operate-api-model';
import { I18nMapperService } from './i18n-mapper.service';
import { I18nListService } from '../gridfilter/i18n-list.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class I18nDataService {

  constructor(
    private i18nListService: I18nListService,
    private logger: LoggingService,
    private i18nMapperService: I18nMapperService,
  ) {
  }

  getTranslations(lang: string, group: string): Promise<any> {
    return this.i18nListService
      .requestList({
        gridFilterPaging: new GridFilterPaging(),
        orderBy: [],
        filter: {
          and: [
            {
              equals: {
                'Group': group
              }
            },
            {
              equals: {
                'Language.Code': lang
              }
            }
          ]
        }
      })
      .toPromise()
      .then(res => this.i18nMapperService.listToDictionary(res.result));
  }

  getTranslations$(lang: string, group: string): Observable<any> {
    return this.i18nListService
      .requestList({
        gridFilterPaging: new GridFilterPaging(),
        orderBy: [],
        filter: {
          and: [
            {
              equals: {
                'Group': group
              }
            },
            {
              equals: {
                'Language.Code': lang
              }
            }
          ]
        }
      })
      .pipe(map(res => this.i18nMapperService.listToDictionary(res.result)));
  }
}
