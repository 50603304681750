import { Action } from '@ngrx/store';
import { UserEditViewModel, UserViewModel } from '@circlon/operate-api-model';

export enum ActionTypes {
  USER_LOAD = '[User] User Load',
  USER_UPDATE = '[User] User Update',
  USER_FAILURE = '[User] User Failure',
  USER_SUCCESS = '[User] User Success',
  USER_SAVED = '[User] User saved',
  USER_LOGOUT = '[User] User logout',
  USER_PROFILE_UPDATE = '[User Profile Page] User profile update',
  USER_PROFILE_UPDATED = '[User Profile Page] User profile updated',
  USER_RESET_PASSWORD = '[User Reset Page] User reset password',
  USER_RESET_PASSWORD_FAILURE = '[User Account Api] User reset password failure',
  USER_RESET_PASSWORD_SUCCESS = '[User Account Api] User reset password success',
  USER_RESET_PASSWORD_RESET = '[User Reset Page] Reset user password reset data',
  USER_SET_NEW_PASSWORD = '[User Set New Password Page] Set new user password',
  USER_SET_NEW_PASSWORD_FAILURE = '[User Account Api] Set new user password failure',
  USER_SET_NEW_PASSWORD_SUCCESS = '[User Account Api] Set new user password success',
  USER_STORAGE_WRITE_SUCCESS = '[User] User storage write success',
  USER_SET_NEW_PASSWORD_FROM_EXPIRED = '[Auth Page] Set new user password after expiration',
  USER_SET_NEW_PASSWORD_FROM_EXPIRED_FAILURE = '[User Account Api] Set new user password after expiration failure',
  USER_SET_NEW_PASSWORD_FROM_EXPIRED_SUCCESS = '[User Account Api] Set new user password after expiration success',
  USER_SET_SCREEN_WIDTH = '[User] Set user screen width',
  USER_CLEAR_SCREEN_WIDTH = '[User] Clear user screen width',
}

export class UserLoadAction implements Action {
  readonly type = ActionTypes.USER_LOAD;
}

export class UserUpdateAction implements Action {
  readonly type = ActionTypes.USER_UPDATE;

  constructor(public payload: { user: UserViewModel }) {}
}

export class UserFailureAction implements Action {
  readonly type = ActionTypes.USER_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UserSuccessAction implements Action {
  readonly type = ActionTypes.USER_SUCCESS;

  constructor(public payload: { user: UserViewModel }) {}
}

export class UserSavedAction implements Action {
  readonly type = ActionTypes.USER_SAVED;
}

export class UserLogoutAction implements Action {
  readonly type = ActionTypes.USER_LOGOUT;
}

export class UserProfileUpdateAction implements Action {
  readonly type = ActionTypes.USER_PROFILE_UPDATE;

  constructor(public payload: { user: UserEditViewModel }) {}
}

export class UserProfileUpdatedAction implements Action {
  readonly type = ActionTypes.USER_PROFILE_UPDATED;
}

export class UserResetPasswordAction implements Action {
  readonly type = ActionTypes.USER_RESET_PASSWORD;

  constructor(public payload: { email: string}) {}
}

export class UserResetPasswordFailureAction implements Action {
  readonly type = ActionTypes.USER_RESET_PASSWORD_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UserResetPasswordSuccessAction implements Action {
  readonly type = ActionTypes.USER_RESET_PASSWORD_SUCCESS;
}

export class UserResetPasswordResetAction implements Action {
  readonly type = ActionTypes.USER_RESET_PASSWORD_RESET;
}

export class UserSetNewPasswordAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD;

  constructor(public payload: { password: string }) {}
}

export class UserSetNewPasswordFailureAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UserSetNewPasswordSuccessAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD_SUCCESS;

  constructor(public payload: { password: string }) {}
}

export class UserStorageWriteSuccess implements Action {
  readonly type = ActionTypes.USER_STORAGE_WRITE_SUCCESS;
}

export class UserSetNewPasswordFromExpiredAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED;

  constructor(public payload: { password: string, newPassword: string, passwordConfirmation: string }) {}
}

export class UserSetNewPasswordFromExpiredFailureAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED_FAILURE;

  constructor(public payload: { error: any }) {}
}

export class UserSetNewPasswordFromExpiredSuccessAction implements Action {
  readonly type = ActionTypes.USER_SET_NEW_PASSWORD_FROM_EXPIRED_SUCCESS;

  constructor(public payload: { password: string }) {}
}

export class UserSetScreenWidthAction implements Action {
  readonly type = ActionTypes.USER_SET_SCREEN_WIDTH;

  constructor(public payload: { width: number }) {}
}

export class UserClearScreenWidthAction implements Action {
  readonly type = ActionTypes.USER_CLEAR_SCREEN_WIDTH;
}

export type Actions =
  UserLoadAction
  | UserUpdateAction
  | UserFailureAction
  | UserSuccessAction
  | UserSavedAction
  | UserLogoutAction
  | UserProfileUpdateAction
  | UserProfileUpdatedAction
  | UserResetPasswordAction
  | UserResetPasswordFailureAction
  | UserResetPasswordSuccessAction
  | UserResetPasswordResetAction
  | UserSetNewPasswordAction
  | UserSetNewPasswordFailureAction
  | UserSetNewPasswordSuccessAction
  | UserStorageWriteSuccess
  | UserSetNewPasswordFromExpiredAction
  | UserSetNewPasswordFromExpiredFailureAction
  | UserSetNewPasswordFromExpiredSuccessAction
  | UserSetScreenWidthAction
  | UserClearScreenWidthAction;
