import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ChangePasswordViewModel, ResetAccountViewModel, SetNewPasswordViewModel, UserViewModel } from '@circlon/operate-api-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable()
export class AccountService {

  private baseUrl = `${this.appConfig.apiBaseEndpoint}v1.0/account`;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
  }

  getCurrentUser$(): Observable<UserViewModel> {
    const url = `${this.baseUrl}/getcurrentuser`;
    return this.httpClient.get<UserViewModel>(url)
      .pipe(map(res => UserViewModel.fromJS(res)));
  }

  resetAccount$(model: ResetAccountViewModel): Observable<void> {
    const url = `${this.baseUrl}/resetaccount`;
    return this.httpClient.post<void>(url, model);
  }

  changePassword(model: ChangePasswordViewModel): Promise<void> {
    const url = `${this.baseUrl}/changepassword`;
    return this.httpClient.post<void>(url, model)
      .toPromise();
  }

  changePassword$(model: ChangePasswordViewModel): Observable<void> {
    const url = `${this.baseUrl}/changepassword`;
    return this.httpClient.post<void>(url, model);
  }

  setNewPassword$(model: SetNewPasswordViewModel): Observable<void> {
    const url = `${this.baseUrl}/setpassword`;
    return this.httpClient.post<void>(url, model);
  }

  updateLanguage(languageCode: string): Promise<UserViewModel> {
    const url = `${this.baseUrl}/updateLanguage/${languageCode}`;
    return this.httpClient.post<UserViewModel>(url, {})
      .toPromise()
      .then(res => UserViewModel.fromJS(res));
  }
}
