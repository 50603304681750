import { Action } from '@ngrx/store';
import { ContextTargetData } from '@circlon/operate-api-model';

export enum ActionTypes {
  LOAD_IMPERSONATION_TARGETS = '[Impersonation] Load impersonation targets',
  LOAD_IMPERSONATION_TARGETS_LOCAL = '[Impersonation] Load impersonation targets from local storage',
  LOAD_IMPERSONATION_TARGETS_FAIL = '[Impersonation] Load impersonation targets fail',
  LOAD_IMPERSONATION_TARGETS_SUCCESS = '[Impersonation] Load impersonation targets success',
  LOAD_IMPERSONATION_TARGET_SUCCESS = '[Impersonation] Load impersonation target success',
  SET_IMPERSONATION = '[Impersonation] Set impersonation',
  SET_IMPERSONATION_SUCCESS = '[Impersonation] Impersonation set success',
  SET_IMPERSONATION_AFTER_LOGIN = '[Impersonation] Set impersonation after login',
  IMPERSONATION_LOGOUT = '[Impersonation] Impersonation logout',
  IMPERSONATION_STORAGE_WRITE_SUCCESS = '[Impersonation] Impersonation storage write',
}

export class LoadImpersonationTargets implements Action {
  readonly type = ActionTypes.LOAD_IMPERSONATION_TARGETS;
}

export class LoadImpersonationTargetsLocal implements Action {
  readonly type = ActionTypes.LOAD_IMPERSONATION_TARGETS_LOCAL;
}

export class LoadImpersonationTargetsFail implements Action {
  readonly type = ActionTypes.LOAD_IMPERSONATION_TARGETS_FAIL;

  constructor(public payload: { error: string }) {}
}

export class LoadImpersonationTargetsSuccess implements Action {
  readonly type = ActionTypes.LOAD_IMPERSONATION_TARGETS_SUCCESS;

  constructor(public payload: ContextTargetData[]) {}
}

export class LoadImpersonationTargetSuccess implements Action {
  readonly type = ActionTypes.LOAD_IMPERSONATION_TARGET_SUCCESS;

  constructor(public payload: ContextTargetData) {}
}

export class SetImpersonation implements Action {
  readonly type = ActionTypes.SET_IMPERSONATION;

  constructor(public payload: ContextTargetData) {}
}

export class SetImpersonationSuccess implements Action {
  readonly type = ActionTypes.SET_IMPERSONATION_SUCCESS;
}

export class SetImpersonationAfterLogin implements Action {
  readonly type = ActionTypes.SET_IMPERSONATION_AFTER_LOGIN;
}

export class ImpersonationLogout implements Action {
  readonly type = ActionTypes.IMPERSONATION_LOGOUT;
}

export class ImpersonationStorageWriteSuccessAction implements Action {
  readonly type = ActionTypes.IMPERSONATION_STORAGE_WRITE_SUCCESS;
}

// action types
export type Actions =
  LoadImpersonationTargets
  | LoadImpersonationTargetsLocal
  | LoadImpersonationTargetsFail
  | LoadImpersonationTargetsSuccess
  | LoadImpersonationTargetSuccess
  | SetImpersonation
  | SetImpersonationSuccess
  | SetImpersonationAfterLogin
  | ImpersonationLogout
  | ImpersonationStorageWriteSuccessAction;
