import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContextTargetData, CurrentContextViewModel } from '@circlon/operate-api-model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Injectable({
  providedIn: 'root'
})
export class ContextDataService {

  private baseUrl = `${this.appConfig.apiBaseEndpoint}v1.0/context`;

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
  }

  getImpersonationTargets$(): Observable<ContextTargetData[]> {
    const url = `${this.baseUrl}/target`;
    return this.httpClient.get<({ data: ContextTargetData[] })>(url)
      .pipe(map(obj => {
        return obj.data;
      }));
  }

  getCurrentContext(): Promise<CurrentContextViewModel> {
    const url = `${this.baseUrl}`;
    return this.httpClient.get<CurrentContextViewModel>(url)
      .toPromise()
      .then(res => CurrentContextViewModel.fromJS(res));
  }
}
