import { Component, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Subject } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { filter, takeUntil } from 'rxjs/operators';
import { APP_CONFIG, AppConfig } from '@app/app.config';

@Component({
  selector: 'opr-splashscreen',
  templateUrl: './splashscreen.component.html',
  styleUrls: ['./splashscreen.component.scss'],
  animations: [
    trigger('visible', [
      state('true', style({opacity: '1'})),
      state('false', style({opacity: '0', display: 'none'})),
      transition('true => false', animate(500)),
    ]),
  ],
})

export class SplashscreenComponent {

  private ngUnsubscribe = new Subject();
  visible = true;
  branding = this.appConfig.branding;
  brandName = this.appConfig.brandName;

  constructor(
    private router: Router,
    @Inject(APP_CONFIG) private appConfig: AppConfig,
  ) {
    router.events
      .pipe(
        filter(ev => ev instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.visible = false;
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
      });
  }
}
