import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RouteKeyService {
  // Asset
  static assets = 'assets';

  // Service
  static serviceCategory = 'service';
  static rma = 'rma';
  static rollIn = 'roll-in';

  // Report
  static reports = 'reports';

  // Devices category
  static assetConfigurationCategory = 'devices';
  static assetType = 'assetType';
  static tenantAssetDefinition = 'customerModel';
  static assetDefinition = 'deviceModel';
  static statusGroup = 'statusGroup';

  // Admin
  static adminCategory = 'admin';
  static user = 'user';
  static role = 'role';
  static serviceContract = 'serviceContract';
  static serviceProvider = 'serviceProvider';
  static exchangePool = 'exchangePool';
  static tenant = 'tenant';
  static manufacturer = 'manufacturer';
  static translation = 'translation';
  static batch = 'batch';

  // common route names
  static detail = 'detail';
  static create = 'create';
}
