import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CssFeaturesService, PAGE_FEATURES_DEFAULTS } from '@css-features/css-features.service';
import { CssFeatures } from '@css-features/css-features.enum';
import { MissingTranslationHandler, TranslateModule } from '@ngx-translate/core';
import { SplashscreenComponent } from '@splashscreen/splashscreen.component';
import { SharedModule } from '@shared/shared.module';
import { TranslationMissingHandler } from '@shared/services/translation/translation-missing.handler';
import { LOG_LEVEL, LoggingService } from '@shared/services/logging/logging.service';
import { LoggingLevel } from '@shared/services/logging/logging-level.enum';
import { AuthGuard } from '@shared/services/auth-guard/auth-guard.service';
import { TranslationResolver } from '@shared/services/translation/translation.resolve';
import { TranslationService } from '@shared/services/translation/translation.service';
import { RootStoreModule } from '@app/root-store';
import { environment } from '@environments/environment';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    SplashscreenComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    SharedModule.forRoot(),
    TranslateModule.forRoot({
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: TranslationMissingHandler, deps: [LoggingService]}
    }),
    RootStoreModule,
  ],
  providers: [
    CssFeaturesService,
    {
      provide: LOG_LEVEL,
      useValue: environment.production ? LoggingLevel.Debug : LoggingLevel.Info,
    },
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslationService) => {
        return translate.currentLanguage;
      },
      deps: [TranslationService]
    },
    {
      provide: PAGE_FEATURES_DEFAULTS,
      useValue: [
        CssFeatures.HeaderFixed,
        CssFeatures.SidebarFixed,
        CssFeatures.SidebarMinimized,
        CssFeatures.AsideMenuFixed,
        CssFeatures.SidebarShow,
      ]
    },
    TranslationResolver,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
