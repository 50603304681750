import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BsControlStatusDirective } from './directives/bs-control-status/bs-control-status.directive';
import { LoadingIndicatorComponent } from './components/loading-indicator/loading-indicator.component';
import { AlertComponent } from './components/alert/alert.component';
import { ErrorMessageComponent } from './components/error/error-message/error-message.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { InputErrorDisplayComponent } from './components/input-error-display/input-error-display.component';
import { LoadingBarInterceptorService } from './services/loading-bar/loading-bar-interceptor.service';
import { HttpErrorInterceptorService } from './services/http-error/http-error-interceptor.service';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { ConsoleLoggingService } from './services/logging/console-logging.service';
import { LoggingService } from './services/logging/logging.service';
import { RefDeserializeInterceptor } from './services/ref-deserialize/ref-deserialize.interceptor';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { AccountService } from './services/account/account.service';
import { AuthGuard } from './services/auth-guard/auth-guard.service';
import { LoadingBarService } from './services/loading-bar/loading-bar.service';
import { I18nDataService } from './services/i18n-data/i18n-data.service';
import { I18nMapperService } from './services/i18n-data/i18n-mapper.service';
import { I18nListService } from './services/gridfilter/i18n-list.service';
import { TranslationKeyService } from './services/translation-key/translation-key.service';
import { AlertService } from './services/alert/alert.service';
import { PermissionService } from './services/permission/permission.service';
import { TranslationService } from './services/translation/translation.service';
import { ResponseErrorPipe } from './pipes/response-error/response-error.pipe';
import { SetPasswordGuard } from './services/set-password-guard/set-password-guard.service';
import { ContextDataService } from '@shared/services/context/context-data.service';
import { PermissionKeyService } from '@shared/services/permission/permission-key.service';
import { CollapseService } from '@shared/services/collapse/collapse.service';
import { MonitoringErrorHandlerService } from '@shared/services/monitoring/monitoring-error-handler.service';
import { OauthService } from '@shared/services/oauth/oauth.service';
import { GridFilterDataModule } from '@operate/grid-filter/data';
import { PasswordHintsComponent } from './components/password-hints/password-hints.component';
import { RefreshInterceptor } from '@shared/services/refresh/refresh.interceptor';
import { LoadingOverlayComponent } from '@shared/components/loading-overlay/loading-overlay.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AlertModule } from 'ngx-bootstrap/alert';
import { InputTrimDirective } from './directives/input-trim.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    TranslateModule,
    AlertModule.forRoot(),
    GridFilterDataModule,
    MatProgressSpinnerModule,
  ],
  declarations: [
    BsControlStatusDirective,
    InputErrorDisplayComponent,
    LoadingBarComponent,
    AlertComponent,
    LoadingIndicatorComponent,
    ErrorMessageComponent,
    ResponseErrorPipe,
    PasswordHintsComponent,
    LoadingOverlayComponent,
    InputTrimDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingBarInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefreshInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RefDeserializeInterceptor,
      multi: true,
    },
    {
      provide: LoggingService,
      useClass: ConsoleLoggingService,
    },
    {
      provide: ErrorHandler,
      useClass: MonitoringErrorHandlerService,
    }
  ],
  exports: [
    BsControlStatusDirective,
    InputErrorDisplayComponent,
    LoadingBarComponent,
    AlertComponent,
    LoadingIndicatorComponent,
    PasswordHintsComponent,
    LoadingOverlayComponent,
    InputTrimDirective,
    //
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ErrorMessageComponent,
    ResponseErrorPipe,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        LocalStorageService,
        AuthGuard,
        SetPasswordGuard,
        AccountService,
        LoadingBarService,
        I18nDataService,
        I18nMapperService,
        I18nListService,
        TranslationKeyService,
        AlertService,
        PermissionService,
        TranslationService,
        ResponseErrorPipe,
        ContextDataService,
        PermissionKeyService,
        CollapseService,
        OauthService,
      ]
    };
  }

  static forChild(): ModuleWithProviders<SharedModule> {
    return {ngModule: SharedModule};
  }
}
