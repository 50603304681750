import { initialState } from './router.state';
import { ActionTypes, RouterActions } from './router.actions';

export function featureReducer(state = initialState, action: RouterActions) {
  switch (action.type) {
    case ActionTypes.ROUTER_SET_CURRENT_ROUTE: {
      return {
        ...state,
        currentRoute: state.newRoute,
      };
    }

    case ActionTypes.ROUTER_RESET_NEW_ROUTE: {
      return {
        ...state,
        newRoute: []
      };
    }

    case ActionTypes.ROUTER_ADD_TO_NEW_ROUTE: {
      return {
        ...state,
        newRoute: [...state.newRoute, action.payload.route],
      };
    }

    case ActionTypes.ROUTER_SET_CURRENT_PARENT_ACTIVATION: {
      return {
        ...state,
        currentParentActivationUrl: action.payload.activation,
      };
    }

    case ActionTypes.ROUTER_SET_TRANSLATION_GROUPS: {
      return {
        ...state,
        translationGroups: action.payload.groups,
      };
    }

    case ActionTypes.ROUTER_SET_FILTER: {
      return {
        ...state,
        filter: action.payload
      };
    }

    case ActionTypes.ROUTER_CLEAR_FILTER: {
      return {
        ...state,
        filter: null,
      };
    }

    default: {
      return state;
    }
  }
}
