import { Pipe, PipeTransform } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslationKeyService } from '../../services/translation-key/translation-key.service';

@Pipe({
  name: 'responseError'
})
export class ResponseErrorPipe implements PipeTransform {

  constructor(private translationKeyService: TranslationKeyService) {
  }

  transform(error: HttpErrorResponse): string {
    if (error) {
      return this.getErrorMessageTranslationKey(this.extractErrorMessage(error));
    }
    return null;
  }

  private extractErrorMessage(error: HttpErrorResponse): string {
    if (error.error) {
      const err = error.error;

      if (err instanceof Array && err.length > 0) {
        return err[0];
      }

      if (err.errors instanceof Array && err.errors.length > 0) {
        return err.errors[0].errorCode;
      } else if (err.message) {
        return err.message;
      } else if (err.error) {
        return err.error;
      } else {
        return 'ErrorResponse';
      }
    }
  }

  private getErrorMessageTranslationKey(errorMessage: string) {
    return this.translationKeyService.generateTranslationKey('common', 'error', errorMessage);
  }

}
