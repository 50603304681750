<div class="loading-overlay">
  <ng-container *ngIf="!showSuccess && visible" >
    <mat-spinner [color]="'accent'" [diameter]="spinnerSize"></mat-spinner>
  </ng-container>

  <ng-container #success *ngIf="showSuccess && visible">
    <span class="success-icon oi oi-check"></span>
  </ng-container>

  <p *ngIf="text !== ''" class="mt-4">{{text | translate : textParams}}{{showSuccess ? '!' : '...'}}</p>

  <button *ngIf="!showSuccess && visible && cancelable"
          (click)="emitCancel()"
          class="btn btn-default"
          [title]="'OPERATE.CANCEL' | translate">{{'OPERATE.CANCEL' | translate}}</button>
</div>
