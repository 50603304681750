import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as featureActions from './impersonation.actions';
import { ContextDataService } from '@shared/services/context/context-data.service';
import { LocalStorageService } from '@shared/services/local-storage/local-storage.service';
import { RootStoreState } from '@app/root-store';
import { Router } from '@angular/router';
import { SessionPath } from '@shared/models/session-path.model';
import { ContextTargetData } from '@circlon/operate-api-model';
import * as impersonationActions from './impersonation.actions';
import { State as ImpersonationState } from './impersonation.state';

@Injectable()
export class ImpersonationStoreEffects {

  constructor(
    private dataService: ContextDataService,
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store$: Store<RootStoreState.State>,
    private router: Router,
  ) {}

  @Effect()
  loadRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<featureActions.LoadImpersonationTargets>(
      featureActions.ActionTypes.LOAD_IMPERSONATION_TARGETS
    ),
    switchMap(() =>
      this.dataService
        .getImpersonationTargets$()
        .pipe(
          map(targets => new featureActions.LoadImpersonationTargetsSuccess(targets)),
          catchError(error => of(new featureActions.LoadImpersonationTargetsFail({ error })))
        )
    )
  );

  @Effect()
  loadLocalRequestEffect$: Observable<Action> = this.actions$.pipe(
    ofType<featureActions.LoadImpersonationTargetsLocal>(
      featureActions.ActionTypes.LOAD_IMPERSONATION_TARGETS_LOCAL
    ),
    switchMap(() => {
      const targets = this.localStorageService.getItem(SessionPath.TARGETS);
      if (targets) {
        return of(new featureActions.LoadImpersonationTargetsSuccess(targets));
      } else {
        return this.dataService
          .getImpersonationTargets$()
          .pipe(
            map(
              apiTargets => new featureActions.LoadImpersonationTargetsSuccess(apiTargets)
            ),
            catchError(error =>
              of(new featureActions.LoadImpersonationTargetsFail({ error }))
            )
          );
      }
    })
  );

  @Effect()
  setImpersonationEffect$: Observable<Action> = this.actions$.pipe(
    ofType<featureActions.SetImpersonation>(
      featureActions.ActionTypes.SET_IMPERSONATION
    ),
    withLatestFrom(
      this.store$.select(state => state.user.user),
      this.store$.select(state => state.router.currentParentActivationUrl),
      this.store$.select(state => state.router.currentRoute),
    ),
    switchMap(([action, user, currentParentActivationUrl, currentRoute]) => {
      this.localStorageService.setUserItem(user.id, SessionPath.CONTEXT, action.payload);

      if (currentParentActivationUrl && currentParentActivationUrl.length) {
        this.router.navigate(currentRoute);
      }

      window.location.reload();

      return of(new featureActions.SetImpersonationSuccess());
    })
  );

  @Effect()
  loadImpersonationSuccessEffect$ = this.actions$.pipe(
    ofType<featureActions.LoadImpersonationTargetsSuccess>(featureActions.ActionTypes.LOAD_IMPERSONATION_TARGETS_SUCCESS),
    switchMap(action => {
      this.localStorageService.setItem(SessionPath.TARGETS, action.payload);
      return of(new featureActions.ImpersonationStorageWriteSuccessAction());
    })
  );

  @Effect()
  setImpersonationAfterLoginEffect$ = this.actions$.pipe(
    ofType<featureActions.SetImpersonationAfterLogin>(featureActions.ActionTypes.SET_IMPERSONATION_AFTER_LOGIN),
    withLatestFrom(
      this.store$.select(state => state.impersonation),
      this.store$.select(state => state.user.user),
    ),
    switchMap(([action, impersonationState, user]) => {
      const target: ContextTargetData = this.localStorageService.getUserItem(user.id, SessionPath.CONTEXT);
      if (target) {
        if (this.targetCanBeImpersonated(target, impersonationState)) {
          return of(new impersonationActions.SetImpersonation(target));
        } else {
          this.localStorageService.setUserItem(user.id, SessionPath.CONTEXT, null);
          return EMPTY;
        }
      } else {
        return EMPTY;
      }
    })
  );

  @Effect()
  impersonationLogoutEffect$ = this.actions$.pipe(
    ofType<featureActions.ImpersonationLogout>(featureActions.ActionTypes.IMPERSONATION_LOGOUT),
    switchMap(() => {
      this.localStorageService.setItem(SessionPath.TARGETS, null);
      return of(new featureActions.ImpersonationStorageWriteSuccessAction());
    })
  );

  private targetCanBeImpersonated(target: ContextTargetData, impersonationState: ImpersonationState) {
    // no impersonation targets
    if (impersonationState.ids.length === 0) {
      return false;
    }

    // check impersonation targets for target
    return impersonationState.ids.some(targetId => {
      if (targetId === target.tenantId) {
        // target is in first level
        return true;
      } else if (impersonationState.entities[targetId].children) {
        // check all children for their id
        return impersonationState.entities[targetId].children.some(child => {
          return child.tenantId === target.tenantId;
        });
      } else {
        // no children to check
        return false;
      }
    });
  }

}
