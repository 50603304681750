import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { LoggingService } from '../logging/logging.service';

export class TranslationMissingHandler implements MissingTranslationHandler {
  constructor(private logger: LoggingService) {
  }

  handle(params: MissingTranslationHandlerParams) {
    this.logger.error('Missing Translation:', params.key, 'lang:', params.translateService.currentLang);
    return params.key;
  }
}
