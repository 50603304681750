import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { ActionsSubject, Store } from '@ngrx/store';
import { AuthStoreActions, RootStoreState } from '@app/root-store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, mergeMap, skipWhile, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RefreshInterceptor implements HttpInterceptor {

  constructor(
    private store$: Store<RootStoreState.State>,
    private actions$: ActionsSubject,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 && err.statusText === 'Token Expired') {

              this.store$.dispatch(new AuthStoreActions.AuthRefreshSingleAction());

              return this.actions$.pipe(
                skipWhile(action =>
                  action.type !== AuthStoreActions.ActionTypes.AUTH_REFRESH_SUCCESS
                  && action.type !== AuthStoreActions.ActionTypes.AUTH_REFRESH_FAILURE
                  && action.type !== AuthStoreActions.ActionTypes.AUTH_EXPIRED_LOGIN_SUCCESS
                  && action.type !== AuthStoreActions.ActionTypes.AUTH_EXPIRED_LOGIN_REROUTE_NEEDED
                ),
                take(1),
                mergeMap(action => {
                  if (
                    action.type === AuthStoreActions.ActionTypes.AUTH_REFRESH_SUCCESS
                    || action.type === AuthStoreActions.ActionTypes.AUTH_EXPIRED_LOGIN_SUCCESS
                  ) {
                    return next.handle(request);
                  } else if (action.type === AuthStoreActions.ActionTypes.AUTH_EXPIRED_LOGIN_REROUTE_NEEDED) {
                    return EMPTY;
                  } else {
                    return throwError(new HttpErrorResponse({
                      error: { error: 'AuthorizationHasBeenDeniedForThisRequest' },
                      headers: request.headers,
                      status: 401,
                      statusText: 'Unauthorized',
                      url: request.url,
                    }));
                  }
                })
              );
            } else {
              return throwError(err);
            }
          } else {
            return throwError(err);
          }
        })
      );
  }

}
