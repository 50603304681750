import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { MonitoringService } from '@shared/services/monitoring/monitoring.service';

@Injectable({
  providedIn: 'root'
})
export class MonitoringErrorHandlerService extends ErrorHandler {

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: any): void {
    const monitoringService = this.injector.get(MonitoringService);
    monitoringService.logError(error);
    super.handleError(error);
  }

}
