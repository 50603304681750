import { Injectable } from '@angular/core';
import { TenantThemeModel } from '@circlon/operate-api-model';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  clearTheme() {
    document.documentElement.style.removeProperty('--primary');
    document.documentElement.style.removeProperty('--primary-darker');
    document.documentElement.style.removeProperty('--primary-dark');
    document.documentElement.style.removeProperty('--font-family');
    document.documentElement.style.removeProperty('--bg');
    document.documentElement.style.removeProperty('--border-radius');
    document.documentElement.style.removeProperty('--header-border-width');
    document.documentElement.style.removeProperty('--header-border-color');
    document.documentElement.style.removeProperty('--table-loading-contrast');
    document.documentElement.style.removeProperty('--nav-bg');
    document.documentElement.style.removeProperty('--nav-toggle');
    document.documentElement.style.removeProperty('--nav-toggle-active');
    document.documentElement.style.removeProperty('--nav-item');
    document.documentElement.style.removeProperty('--nav-item-link-color');
    document.documentElement.style.removeProperty('--nav-item-color');
    document.documentElement.style.removeProperty('--nav-item-active');
    document.documentElement.style.removeProperty('--nav-item-active-color');
    document.documentElement.style.removeProperty('--nav-item-active-link-color');
    document.documentElement.style.removeProperty('--nav-item-hover');
    document.documentElement.style.removeProperty('--nav-item-hover-color');
    document.documentElement.style.removeProperty('--nav-item-hover-link-color');
    document.documentElement.style.removeProperty('--nav-border-right');
    document.documentElement.style.removeProperty('--gray-50');
    document.documentElement.style.removeProperty('--gray-75');
    document.documentElement.style.removeProperty('--gray-100');
    document.documentElement.style.removeProperty('--gray-200');
    document.documentElement.style.removeProperty('--gray-300');
    document.documentElement.style.removeProperty('--gray-400');
    document.documentElement.style.removeProperty('--gray-500');
    document.documentElement.style.removeProperty('--gray-600');
    document.documentElement.style.removeProperty('--gray-700');
    document.documentElement.style.removeProperty('--gray-800');
    document.documentElement.style.removeProperty('--gray-900');
    document.documentElement.style.removeProperty('--gray-950');
  }

  applyTheme(theme: TenantThemeModel) {
    this.applyGeneralTheme(theme);
    this.applyNavTheme(theme);
    this.applyGrayTheme(theme);
  }

  private applyGeneralTheme(theme: TenantThemeModel) {
    if (theme.primary) {
      document.documentElement.style.setProperty('--primary', theme.primary);
    }
    if (theme.primaryDarker) {
      document.documentElement.style.setProperty('--primary-darker', theme.primaryDarker);
    }
    if (theme.primaryDark) {
      document.documentElement.style.setProperty('--primary-dark', theme.primaryDark);
    }
    if (theme.fontFamily) {
      document.documentElement.style.setProperty('--font-family', theme.fontFamily);
    }
    if (theme.bg) {
      document.documentElement.style.setProperty('--bg', theme.bg);
    }
    if (theme.borderRadius) {
      document.documentElement.style.setProperty('--border-radius', theme.borderRadius);
    }
    if (theme.headerBorderWidth) {
      document.documentElement.style.setProperty('--header-border-width', theme.headerBorderWidth);
    }
    if (theme.headerBorderColor) {
      document.documentElement.style.setProperty('--header-border-color', theme.headerBorderColor);
    }
    if (theme.tableLoadingContrast) {
      document.documentElement.style.setProperty('--table-loading-contrast', theme.tableLoadingContrast);
    }
  }

  private applyNavTheme(theme: TenantThemeModel) {
    if (theme.navBg) {
      document.documentElement.style.setProperty('--nav-bg', theme.navBg);
    }
    if (theme.navToggle) {
      document.documentElement.style.setProperty('--nav-toggle', theme.navToggle);
    }
    if (theme.navToggleActive) {
      document.documentElement.style.setProperty('--nav-toggle-active', theme.navToggleActive);
    }
    if (theme.navItem) {
      document.documentElement.style.setProperty('--nav-item', theme.navItem);
    }
    if (theme.navItemLinkColor) {
      document.documentElement.style.setProperty('--nav-item-link-color', theme.navItemLinkColor);
    }
    if (theme.navItemColor) {
      document.documentElement.style.setProperty('--nav-item-color', theme.navItemColor);
    }
    if (theme.navItemActive) {
      document.documentElement.style.setProperty('--nav-item-active', theme.navItemActive);
    }
    if (theme.navItemActiveColor) {
      document.documentElement.style.setProperty('--nav-item-active-color', theme.navItemActiveColor);
    }
    if (theme.navItemActiveLinkColor) {
      document.documentElement.style.setProperty('--nav-item-active-link-color', theme.navItemActiveLinkColor);
    }
    if (theme.navItemHover) {
      document.documentElement.style.setProperty('--nav-item-hover', theme.navItemHover);
    }
    if (theme.navItemHoverColor) {
      document.documentElement.style.setProperty('--nav-item-hover-color', theme.navItemHoverColor);
    }
    if (theme.navItemHoverLinkColor) {
      document.documentElement.style.setProperty('--nav-item-hover-link-color', theme.navItemHoverLinkColor);
    }
    if (theme.navBorderRight) {
      document.documentElement.style.setProperty('--nav-border-right', theme.navBorderRight);
    }
  }

  private applyGrayTheme(theme: TenantThemeModel) {
    if (theme.gray50) {
      document.documentElement.style.setProperty('--gray-50', theme.gray50);
    }
    if (theme.gray75) {
      document.documentElement.style.setProperty('--gray-75', theme.gray75);
    }
    if (theme.gray100) {
      document.documentElement.style.setProperty('--gray-100', theme.gray100);
    }
    if (theme.gray200) {
      document.documentElement.style.setProperty('--gray-200', theme.gray200);
    }
    if (theme.gray300) {
      document.documentElement.style.setProperty('--gray-300', theme.gray300);
    }
    if (theme.gray400) {
      document.documentElement.style.setProperty('--gray-400', theme.gray400);
    }
    if (theme.gray500) {
      document.documentElement.style.setProperty('--gray-500', theme.gray500);
    }
    if (theme.gray600) {
      document.documentElement.style.setProperty('--gray-600', theme.gray600);
    }
    if (theme.gray700) {
      document.documentElement.style.setProperty('--gray-700', theme.gray700);
    }
    if (theme.gray800) {
      document.documentElement.style.setProperty('--gray-800', theme.gray800);
    }
    if (theme.gray900) {
      document.documentElement.style.setProperty('--gray-900', theme.gray900);
    }
    if (theme.gray950) {
      document.documentElement.style.setProperty('--gray-950', theme.gray950);
    }
  }
}
