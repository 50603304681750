import { Injectable } from '@angular/core';
import { PermissionKeyService } from '@shared/services/permission/permission-key.service';
import { RouteKeyService } from '@shared/services/routes/route-key.service';

@Injectable({
  providedIn: 'root'
})
export class StartPageService {

  getFirstRoute(permissions: string[]): string[] {
    if (this.canAccess(PermissionKeyService.assetView, permissions)) {
      return ['/' + RouteKeyService.assets];
    } else if (this.canAccess(PermissionKeyService.rmaView, permissions)) {
      return ['/' + RouteKeyService.serviceCategory, RouteKeyService.rma];
    } else if (this.canAccess(PermissionKeyService.rollInView, permissions)) {
      return ['/' + RouteKeyService.serviceCategory, RouteKeyService.rollIn];
    } else if (this.canAccess(PermissionKeyService.reportView, permissions)) {
      return ['/' + RouteKeyService.reports];
    } else if (this.canAccess(PermissionKeyService.assetTypeView, permissions)) {
      return ['/' + RouteKeyService.assetConfigurationCategory, RouteKeyService.assetType];
    } else if (this.canAccess(PermissionKeyService.tenantAssetDefinitionView, permissions)) {
      return ['/' + RouteKeyService.assetConfigurationCategory, RouteKeyService.tenantAssetDefinition];
    } else if (this.canAccess(PermissionKeyService.assetDefinitionView, permissions)) {
      return ['/' + RouteKeyService.assetConfigurationCategory, RouteKeyService.assetDefinition];
    } else if (this.canAccess(PermissionKeyService.assetDefinitionManagement, permissions)) {
      return ['/' + RouteKeyService.assetConfigurationCategory, RouteKeyService.statusGroup];
    } else if (this.canAccess(PermissionKeyService.userView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.user];
    } else if (this.canAccess(PermissionKeyService.roleView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.role];
    } else if (this.canAccess(PermissionKeyService.serviceContractView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.serviceContract];
    } else if (this.canAccess(PermissionKeyService.serviceProviderView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.serviceProvider];
    } else if (this.canAccess(PermissionKeyService.exchangePoolView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.exchangePool];
    } else if (this.canAccess(PermissionKeyService.tenantView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.tenant];
    } else if (this.canAccess(PermissionKeyService.manufacturerView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.manufacturer];
    } else if (this.canAccess(PermissionKeyService.batchImport, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.batch];
    } else if (this.canAccess(PermissionKeyService.languageView, permissions)) {
      return ['/' + RouteKeyService.adminCategory, RouteKeyService.translation];
    }
    return ['/' + RouteKeyService.assets];
  }

  private canAccess(permission: string, permissions: string[] = []) {
    if (permissions == null || permission.length === 0) {
      return true;
    }
    const filtered = permissions.filter(p => p === permission);
    return filtered.length === 1;
  }

}
